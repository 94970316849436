import { Component, Input } from '@angular/core';
import { ModalService, TModalType } from 'src/app/core/service/modal-state.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  constructor(public modalService: ModalService) {}

  @Input()
  public modalId: TModalType = '';
  @Input()
  public isCloseable = false;

  public get open(): boolean {
    return this.modalService.isModalOpen(this.modalId);
  }

  public closeModal(): void {
    this.modalService.closeModal(this.modalId);
  }
}
