import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy, EmbeddedViewRef } from '@angular/core';
import { asyncScheduler, fromEvent, of, scheduled, Subscription } from 'rxjs';
import { concatAll, debounceTime } from 'rxjs/operators';
import { breakPointsConfig } from 'src/app/core/config/breakpoints.config';
const DEBOUNCE_TIME = 100;

@Directive({
  selector: '[appBreakpointDown], [appBreakpointUp]',
})
export class BreakpointDirective implements OnInit, OnDestroy {
  @Input()
  public appBreakpointUp!: TBreakPointName;

  @Input()
  public appBreakpointDown!: TBreakPointName;

  @Input()
  public set appBreakpointUpOrShowIf(orShowIf: boolean) {
    this.orShowIf = orShowIf;
    this.updateView();
  }

  @Input()
  public set appBreakpointDownOrShowIf(orShowIf: boolean) {
    this.orShowIf = orShowIf;
    this.updateView();
  }

  private orShowIf!: boolean;

  private viewRef: EmbeddedViewRef<any> | null = null;

  private isShouldShownViaBreakpoint!: boolean;

  private subs!: Subscription;

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

  public ngOnInit(): void {
    this.initialize();
  }

  public ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private initialize(): void {
    this.subs = scheduled([of(null), fromEvent(window, 'resize')], asyncScheduler)
      .pipe(concatAll(), debounceTime(DEBOUNCE_TIME))
      .subscribe(() => {
        this.calcIsBreakpointOn();
      });
  }

  private calcIsBreakpointOn(): void {
    const width = window.innerWidth;

    if (this.appBreakpointDown) {
      this.isShouldShownViaBreakpoint = isBreakpointDown(breakPointsConfig, width, this.appBreakpointDown);
    } else if (this.appBreakpointUp) {
      this.isShouldShownViaBreakpoint = isBreakpointUp(breakPointsConfig, width, this.appBreakpointUp);
    }

    this.updateView();
  }

  private updateView(): void {
    if (this.isShouldShownViaBreakpoint || this.orShowIf) {
      if (!this.viewRef) {
        this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.viewRef.markForCheck();
      }
    } else {
      this.viewContainerRef.clear();
      this.viewRef = null;
    }
  }
}

export function isBreakpointUp(config: TBreakPointsConfig, width: number, breakpoint: TBreakPointName): boolean {
  return config[breakpoint] < width;
}
export function isBreakpointDown(config: TBreakPointsConfig, width: number, breakpoint: TBreakPointName): boolean {
  return config[breakpoint] >= width;
}
