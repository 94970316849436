import { AbstractControl, Validators } from '@angular/forms';
import { validEmailSuffixesList } from './const';

// tslint:disable-next-line:function-name
export function EmailValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value && control.value.length && (Validators.email(control) || !isEmailSuffixValid(control.value))) {
    return { email: true };
  }
  return null;
}

function isEmailSuffixValid(value: any): boolean {
  if (typeof value !== 'string') {
    return false;
  }
  const suffix = value.match(/.*\.(.{2,})$/)?.[1];
  return (
    !!suffix &&
    (/^([a-z]){2,3}$/.test(suffix.toLowerCase()) ||
      validEmailSuffixesList.some((item) => item.toLowerCase() === suffix.toLowerCase()))
  );
}
