<div class="cable-cart">
  <div *ngFor="let cableItem of orderState.cableCartItems; let i = index" [attr.data-index]="i" class="cart-item">
    <div class="vehicle-select-row">
      <div class="make-port-item-left">
        <div class="make-port-row-sm">
          <span class="make-port-label">Make and Port:</span>
          <cds-icon
            class="trash-icon sm-show"
            shape="trash"
            size="15"
            (click)="orderState.removeCableItem(i)"
          ></cds-icon>
        </div>
        <div class="make-port-dropdown">
          <app-select
            class="dropdown-menu select-make"
            [(ngModel)]="cableItem.vehicleMake"
            (change)="orderState.setCableproductCode($event, i)"
          >
            <option value="" selected disabled>Select</option>
            <option *ngFor="let vehicle of vehicleDropdownList" [value]="vehicle.value">{{ vehicle.name }}</option>
          </app-select>
          <div class="cart-item-section" *ngIf="cableItem.productCode">
            <div class="cart-item-details">
              <div class="cart-item-img">
                <img
                  class="cart-img"
                  [src]="'/assets/images/' + getCableInfo(cableItem.productCode).imgSrc"
                  alt="cable"
                />
              </div>
              <div class="cart-item-label">
                <div>{{ getCableInfo(cableItem.productCode).name }}</div>
                <div>(connects to {{ getCableInfo(cableItem.productCode).port }} ports)</div>
              </div>
            </div>
            <div>
              <app-input-quantity
                [max]="cableItem.maxInput"
                [quantity]="cableItem.quantity"
                [inputInvalid]="orderState.cableLimitError"
                (valueChange)="orderState.onCableQuantityChanged($event, i)"
              >
              </app-input-quantity>
            </div>
          </div>
        </div>
      </div>
      <div class="make-port-item-right">
        <cds-icon class="trash-icon sm-hide" shape="trash" size="15" (click)="orderState.removeCableItem(i)"></cds-icon>
        <div *ngIf="cableItem.productCode" class="cart-item-price">$0.00</div>
      </div>
    </div>
  </div>
  <div class="add-new-item-row">
    <div class="add-new-item-section">
      <div
        class="add-new-item-btn vwo-highlighted sm-fs16"
        [class.disabled]="addAnotherMakeDisabled()"
        [class.empty-cart]="orderState.cableCartEmpty"
        (click)="addAnotherMakeClicked()"
      >
        <cds-icon shape="plus-circle" size="15"></cds-icon>
        Add another make
      </div>
      <span *ngIf="addAnotherMakeErrorMsg && !orderState.isVendorSpecific">
        <i class="cable-limit-msg">To add another make, increase number of Devices.</i>
      </span>
    </div>
  </div>
</div>
