import { Directive, DoCheck, HostBinding, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appFormError]',
})
export class FormErrorDirective implements DoCheck {
  @HostBinding('hidden')
  public hidden = true;

  @Input('appFormError')
  public controlName!: string;

  @Input()
  public errorName!: string;

  constructor(private form: FormGroupDirective) {}

  public ngDoCheck(): void {
    const control = this.form.control.get(this.controlName);
    this.hidden = !(control?.touched && (this.errorName ? control?.hasError(this.errorName) : control.errors));
  }
}
