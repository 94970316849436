<app-common-layout>
  <div class="container order-container">
    <div class="content left-side">
      <div class="order-confirmation">
        <div class="order-number">
          <img class="check-icon" src="/assets/icons/circle-checkmark.png" />
          <span>Order #12345</span>
        </div>
        <div *ngIf="displayMap" class="order-map">
          <google-map [center]="center" [options]="mapOptions" width="100%" height="100%">
            <map-marker #marker="mapMarker" [position]="center" [options]="markerOptions"></map-marker>
            <map-info-window #infoWindow>
              <div [innerHTML]="markerText"></div>
            </map-info-window>
          </google-map>
        </div>
        <div>Your order is confirmed.</div>
        <div>You'll receive a confirmation email with your order number shortly.</div>
      </div>

      <h3 class="section-header order-details">Order Details</h3>
      <div class="details-table">
        <div class="section">
          <div class="details-info">
            <div class="details-header">Contact:</div>
            <div>{{ shippingAddress?.companyName }}</div>
            <div>{{ orderState.getEmail() }}</div>
            <div>{{ orderState.getPhone() | phone }}</div>
          </div>
          <div class="details-info">
            <div class="details-header">Payment Method:</div>
            <div>{{ orderState.cardWithLastFourDigits }}</div>
          </div>
        </div>
        <div class="section">
          <div *ngIf="shippingAddress" class="details-info">
            <div class="details-header">Shipping Address:</div>
            <div>{{ shippingAddress?.firstName }} {{ shippingAddress?.lastName }}</div>
            <div>{{ shippingAddress?.address1 }}</div>
            <div>{{ shippingAddress?.city }}, {{ shippingAddress?.state }}</div>
            <div>{{ shippingAddress?.postalCode }}</div>
          </div>
          <div *ngIf="billingAddress" class="details-info">
            <div class="details-header">Billing Address:</div>
            <div>{{ billingAddress?.firstName }} {{ billingAddress?.lastName }}</div>
            <div>{{ billingAddress?.address1 }}</div>
            <div>{{ billingAddress?.city }}, {{ billingAddress?.state }}</div>
            <div>{{ billingAddress?.postalCode }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content right-side">
      <div class="separator md-show"></div>

      <h3 class="summary-plan-title section-header">{{ this.planTypeName }} Plan</h3>

      <div *ngIf="!isHybridVendorPlan" class="device-preview md-hide">
        <img [src]="planDevicePreviewImg" alt="tracker device" />
      </div>

      <app-order-plan-summary
        *ngIf="!isHybridVendorPlan; else hybridPlanDetails"
        [isVendorSpecific]="isVendorSpecific"
      ></app-order-plan-summary>

      <ng-template #hybridPlanDetails>
        <app-order-plan-summary [isVendorSpecific]="isVendorSpecific" [sectionPlanType]="'trakViewPro'">
        </app-order-plan-summary>
        <div class="separator"></div>
        <app-order-plan-summary [isVendorSpecific]="isVendorSpecific" [sectionPlanType]="'gpsPremium'">
        </app-order-plan-summary>
      </ng-template>

      <div class="separator"></div>

      <div class="summary-line-item">
        <span>Subtotal:</span>
        <span>{{ orderState.subTotalPrice() | price }}</span>
      </div>

      <ng-container *ngIf="promoCode?.type !== 'invalid' && promoCode?.couponCode">
        <div class="summary-line-item">
          <span>Promo Code:</span>
          <span
            ><b>{{ promoCode?.couponCode }}</b> applied</span
          >
        </div>
        <ng-container [ngSwitch]="promoCode?.type">
          <span *ngSwitchCase="'percentage'"> {{ $any(promoCode)?.discountPercentage }}% off subscription cost </span>
          <span *ngSwitchCase="'fixed'" class="discount-details">
            {{ $any(promoCode)?.fixedAmount | price }} off subscription cost
          </span>
          <span *ngSwitchCase="'free'" class="discount-details">
            Free for the first
            {{ $any(promoCode)?.trialLength }}
            {{ $any(promoCode)?.trialUnit === 'day' ? 'days' : 'months' }}
          </span>
        </ng-container>
        <div class="summary-line-item">
          <span>Discount:</span>
          <span>-{{ orderState.discountAmount() | price }}</span>
        </div>
      </ng-container>

      <div class="summary-line-item">
        <span>Shipping:</span>
        <span>FREE</span>
      </div>
      <div class="summary-line-item">
        <span>Sales Tax:</span>
        <span>{{ orderState.taxesPrice() | price }}</span>
      </div>

      <div class="separator"></div>

      <div class="summary-line-item bold">
        <span>Total:</span>
        <span>{{ orderState.subTotalPriceWithTaxes() | price }}</span>
      </div>
    </div>
  </div>
</app-common-layout>

<app-modal modalId="order-confirmed-modal" [isCloseable]="true">
  <app-order-confirmed-modal></app-order-confirmed-modal>
</app-modal>

<app-survey-modal></app-survey-modal>
