import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodesEndpoints, ICodeResponseDto, IPurchaseablePlanDto } from '../endpoints/codes.endpoints';
import { IPlanPriceModel, IVendorCodeData, IVendorPlan, TPlanTypeModel } from '../models/ibase-price.model';
import { map } from 'rxjs/operators';
import { OrderStateService } from '../service/order-state.service';

@Injectable({ providedIn: 'root' })
export class VendorInfoLoader {
  constructor(private readonly endpoints: CodesEndpoints, private readonly orderState: OrderStateService) {}

  public loadVendorInfo(fulfillmentCode: string): Observable<IVendorCodeData> {
    return this.endpoints
      .validateVendorCode({
        fulfillmentCode,
        clientId: this.endpoints.getClientId(),
      })
      .pipe(map((res) => this.mapVendorCodeData(res)));
  }

  private mapVendorCodeData(dto: ICodeResponseDto): IVendorCodeData {
    return {
      couponCode: dto.couponCode,
      numberOfDevices: dto.numberOfDevices,
      numberOfTrackers: dto.numberOfTrackers,
      numberOfDashcams: dto.numberOfDashcams,
      planCodeTrackersMonthly: dto.planCodeDashcamsMonthly,
      planCodeTrackersAnnual: dto.planCodeTrackersAnnual,
      planCodeDashcamsMonthly: dto.planCodeDashcamsMonthly,
      planCodeDashcamsAnnual: dto.planCodeDashcamsAnnual,
      vendorPlans: this.mapBasePriceModel(dto),
    };
  }

  private mapBasePriceModel(dto: ICodeResponseDto): TPlanTypeModel {
    const { purchaseablePlans: plans } = dto;

    const planTypeModel: TPlanTypeModel = { ...this.orderState.stateValue().subscriptionPlanPrice };

    plans.forEach((plan) => {
      if (plan.planCode === dto.planCodeDashcamsAnnual) {
        planTypeModel.trakViewPro.annual = mapSubsPlan(plan);
      } else if (plan.planCode === dto.planCodeDashcamsMonthly) {
        planTypeModel.trakViewPro.monthly = mapSubsPlan(plan);
      } else if (plan.planCode === dto.planCodeTrackersAnnual) {
        planTypeModel.gpsPremium.annual = mapSubsPlan(plan);
      } else if (plan.planCode === dto.planCodeTrackersMonthly) {
        planTypeModel.gpsPremium.monthly = mapSubsPlan(plan);
      }
    });

    return planTypeModel;

    function mapSubsPlan(plan: IPurchaseablePlanDto): IVendorPlan {
      return {
        shippingRate: plan.flatShippingRate,
        freeShippingQty: plan.freeShippingQuantityRequirement,
        costPerDevice: plan.costPerDevice,
        oneTimeDeviceFee: plan.oneTimeDeviceFee,
        planCode: plan.planCode,
        deviceHardwareCategory: plan.deviceHardwareCategory,
        subscriptionCycle: plan.subscriptionCycle,
      };
    }
  }
}
