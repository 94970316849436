import { Pipe, PipeTransform } from '@angular/core';
import { STATES } from '../constants/states';

@Pipe({
  name: 'stateName',
})
export class StateNamePipe implements PipeTransform {
  transform(value: string = ''): string | undefined {
    return (STATES.find((state) => state.value === value) as { name: string; value: string }).name;
  }
}
