import { Component } from '@angular/core';
import { ModalService } from 'src/app/core/service/modal-state.service';
import { OrderStateService } from 'src/app/core/service/order-state.service';

@Component({
  selector: 'app-popup-message-modal',
  templateUrl: './popup-message-modal.component.html',
  styleUrls: ['./popup-message-modal.component.scss'],
})
export class PopupMessageModalComponent {
  constructor(private orderState: OrderStateService, private modalService: ModalService) {}

  public get transactionErrorMsg(): string {
    const transactionError = this.orderState.transactionError();
    return transactionError
      ? transactionError
      : 'There was a problem processing your order. Please check your order details and submit again.';
  }

  public onBackClicked(): void {
    this.modalService.closeModal('popup-message-modal');
    this.orderState.toggleReview();
  }
}
