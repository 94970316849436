import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { ModalService } from 'src/app/core/service/modal-state.service';
import { IAddressDetails } from 'src/app/core/models/i-order-state.model';
import { FormControl, FormGroup } from '@angular/forms';
import { RecurlyFormComponent } from '../../../../../../shared/components/recurly/components/recurly-form/recurly-form.component';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit {
  @ViewChild('recurlyForm')
  public recurlyForm: RecurlyFormComponent;
  @Input()
  public isReview = false;
  @Input()
  public reCaptchaFailed = false;
  @Input()
  public counter: number | null;

  @Output()
  public toggleReview = new EventEmitter<void>();
  public isValid = false;
  public cardDetailsForm = new FormGroup({
    cardDetails: new FormControl(''),
  });

  public ngOnInit(): void {
    this.isValid = this.orderState.isOrderDetailsValid();
  }

  public get billingAddress(): IAddressDetails | undefined {
    return this.orderState.getBillingAddress();
  }

  public get useSameAddress(): boolean {
    return this.orderState.stateValue().sameBillingAndShippingAddress;
  }

  public get errorTransactionMsg(): string | undefined {
    return this.orderState.stateValue().errorTransactionMsg;
  }

  public get vendorCodeError(): boolean {
    return this.orderState.stateValue().vendorCodeError;
  }

  constructor(public orderState: OrderStateService, public modalService: ModalService) {}

  public onUseSameAddressChange(e: any): void {
    this.orderState.updateState({
      sameBillingAndShippingAddress: e.target.checked,
    });
  }

  public reviewOrder(): void {
    this.toggleReview.emit();
  }

  public async onPayPressed(): Promise<void> {
    const token = await this.recurlyForm.getToken();
    this.orderState.recurlyToken$.next(token);
  }
}
