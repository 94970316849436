<h3 *ngIf="!isReview" class="section-header summary-plan-title md-hide">{{ planTypeName }} Plan</h3>

<span *ngIf="orderState.cableLimitError">
  <i class="reduce-cables-msg">You must reduce the number of cables below to continue.</i>
</span>

<div class="summary-line-item">
  <ng-container *ngIf="!isReview; else reviewDeviceCount">
    <span>Fleet Size (Devices):</span>
    <app-input-quantity
      *ngIf="!isVendorSpecific; else vendorDevices"
      [max]="maxOrderQuantity"
      [quantity]="devicesCount"
      (valueChange)="onDeviceCountChanged($event)"
    ></app-input-quantity>
    <ng-template #vendorDevices>
      <span>{{ devicesCount }}</span>
    </ng-template>
  </ng-container>

  <ng-template #reviewDeviceCount>
    <span [className]="isReview ? 'summary-line-label' : ''"> {{ planTypeName }}: </span>
    <div class="summary-device-wrap">
      <img class="device-img" [src]="planDeviceImg" alt="device" />
      <span>{{ planType === 'trakViewPro' ? 'Dashcam' : 'GPS Tracker' }}</span>
    </div>
    <span>{{ devicesCount }} <span class="md-hide">Devices</span></span>
  </ng-template>
</div>

<div *ngIf="!isVendorSpecific" class="summary-line-item">
  <span [className]="isReview ? 'summary-line-label' : ''">Device Cost:</span>
  <div>
    <span>{{ oneDevicePrice | price }}</span>
  </div>
</div>

<div *ngIf="showHdcOption && !isReview" class="hdc-section">
  <div class="have-trucks-label text-lighter sm-fs16">
    <input type="checkbox" [ngModel]="orderState.haveBigTrucks" (change)="toggleHaveBigTrucks()" />
    <span class="have-big-trucks" (click)="toggleHaveBigTrucks()">Have big trucks?</span>
    <cds-icon
      class="icon-highlighted"
      shape="info-circle"
      solid
      size="20"
      (click)="modalService.openModal('hdc-info-modal')"
    ></cds-icon>
  </div>
  <app-cable-cart *ngIf="orderState.haveBigTrucks"></app-cable-cart>
</div>

<div class="summary-line-item">
  <span [className]="isReview ? 'summary-line-label' : ''">Billing Cycle:</span>
  <div>
    <ng-container *ngIf="!isReview; else billingCycleReview">
      <button
        class="billing-cycle-btn"
        [class.active]="billingPeriod === 'monthly'"
        (click)="onBillingPeriodSwitch('monthly')"
      >
        MONTHLY
      </button>
      <button
        class="billing-cycle-btn"
        [class.active]="billingPeriod === 'annual'"
        (click)="onBillingPeriodSwitch('annual')"
      >
        ANNUAL
      </button>
    </ng-container>
    <ng-template #billingCycleReview>
      <span>{{ billingPeriod === 'monthly' ? 'Monthly' : 'Annual' }}</span>
    </ng-template>
  </div>
</div>

<div class="summary-line-item">
  <span [className]="isReview ? 'summary-line-label' : ''"
    >{{ 'Monthly Subscription' | annual: 'Annual Subscription' }}:</span
  >
  <div>
    <span>{{ devicesCount }} x </span>
    <span>{{ oneVehicleTrackingPrice | price }}</span>
  </div>
</div>

<div *ngIf="showHdcOption && isReview && orderState.cableInCart" class="summary-line-item cable-preview">
  <div class="summary-line-label">Make and Port:</div>
  <div class="flex-grow-1">
    <div
      *ngFor="let cableItem of orderState.cableCartItems; let i = index"
      [attr.data-index]="i"
      class="cart-item-preview"
    >
      <div class="cart-item-details">
        <div class="cart-item-img">
          <img [src]="'/assets/images/' + getCableInfo(cableItem.productCode).imgSrc" class="cart-img" alt="cable" />
        </div>
        <div class="cart-item-label">
          <div>{{ getCableInfo(cableItem.productCode).name }}</div>
          <div>(connects to {{ getCableInfo(cableItem.productCode).port }} ports)</div>
        </div>
      </div>
      <div class="cart-item-price">{{ cableItem.quantity }} x $0.00</div>
    </div>
  </div>
</div>

<ng-container *ngIf="!isReview">
  <div *ngIf="!orderState.isHybridVendorPlan" class="summary-line-item single-right">
    <div class="have-promo-code" (click)="displayPromoCodeInput = true">Have a promo code?</div>
  </div>
  <div class="summary-line-item single-right" *ngIf="displayPromoCodeInput">
    <app-input-promo (apply)="onApplyPromo($event)" [invalidCode]="promoCode?.type === 'invalid'"></app-input-promo>
  </div>
  <div *ngIf="promoCode?.type === 'invalid'" class="summary-line-item single-left invalid-promo">
    Please enter a valid promo code
  </div>
</ng-container>
