/**
 * from _overwrites.scss
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1650px,
 */

export const breakPointsConfig: TBreakPointsConfig = {
  sm: 576,
  md: 960,
  lg: 1320,
};
