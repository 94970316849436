import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TSubscriptionPlan } from '../types/type';

import { AbstractEndpoints } from './abstract.endpoints';

const COUPONS_URL = '{apiUrl}/fleets/fulfillment/coupons';

//
interface IBaseCouponDto {
  couponCode: 'discount50' | 'discount10' | 'discount5' | string;
}

export interface IPercentageBasedCouponDto extends IBaseCouponDto {
  discountPercentage: number;
}

export interface IFixedDiscountCouponDto extends IBaseCouponDto {
  discountFixedAmounts: { value: number; currency: 'USD' }[];
}

export interface IFreeTrialCouponDto extends IBaseCouponDto {
  freeTrialLength: number;
  freeTrialUnit: 'month' | 'day';
}

export type TCouponResponseDto = IPercentageBasedCouponDto | IFixedDiscountCouponDto | IFreeTrialCouponDto;

export interface IRequestCouponDto {
  couponCode: string;
  planCode: TSubscriptionPlan;
  clientId: string;
}

//
@Injectable({ providedIn: 'root' })
export class CouponsEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getCoupon(couponReqDto: IRequestCouponDto): Observable<TCouponResponseDto> {
    return this.httpClient.get<TCouponResponseDto>(this.createUrl(COUPONS_URL), {
      params: { ...couponReqDto },
    });
  }
}
