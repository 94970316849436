import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { DropdownTitleComponent } from './title/dropdown-title.component';

@NgModule({
  declarations: [DropdownComponent, DropdownTitleComponent],
  exports: [DropdownComponent, DropdownTitleComponent],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DropdownModule {}
