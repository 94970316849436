import { Component } from '@angular/core';
import { OrderStateService } from 'src/app/core/service/order-state.service';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss'],
})
export class CommonLayoutComponent {
  constructor(public orderState: OrderStateService) {}
}
