<form class="input-promo" [formGroup]="promoForm" (ngSubmit)="onSubmitForm()">
  <input
    formControlName="promoCode"
    [ngClass]="{ 'invalid-code': invalidCode }"
    class="input"
    type="text"
    placeholder="Promo Code"
  />
  <button class="btn-apply" [disabled]="!promoForm.valid">Apply</button>
</form>
