import { Injectable, Inject, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Recurly } from '@recurly/recurly-js';

export const RECURLY_PUBLIC_KEY = new InjectionToken<string>('RecurlyPublicKey');

@Injectable({
  providedIn: 'root',
})
export class RecurlyScriptLoader {
  private scriptLoadingPromise!: Promise<Recurly>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(RECURLY_PUBLIC_KEY) private readonly recurlyPubKey: string,
  ) {}

  public load(): Promise<Recurly> {
    if (!this.scriptLoadingPromise) {
      const scriptEl = this.document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = this.getScriptSrc();
      // scriptEl.async = true;
      // scriptEl.defer = true;
      this.scriptLoadingPromise = this.createScriptLoadingPromise(scriptEl);
      this.document.body.appendChild(scriptEl);
    }

    return this.scriptLoadingPromise;
  }

  private createScriptLoadingPromise(scriptElem: HTMLElement): Promise<Recurly> {
    return new Promise<Recurly>((resolve: (v: Recurly) => void, reject: (cause: Event | string) => void) => {
      if (!this.recurlyPubKey) {
        return reject('Recurly public key is not defined');
      }
      scriptElem.onload = () => {
        const recurly = (window as any).recurly;
        // recurly.emit = (...args) => console.log('emit', ...args);
        recurly.once('ready', () => {
          resolve(recurly);
        });
        recurly.configure({
          publicKey: this.recurlyPubKey,
        });
      };
      scriptElem.onerror = (error: Event | string) => {
        reject(error);
      };
    });
  }

  private getScriptSrc(): string {
    return 'https://js.recurly.com/v4/recurly.js';
  }

  // private isScriptOnPage(): boolean {
  //   const src = this.getScriptSrc();
  //   const selector = `script[src="${src}"]`;
  //   return !!this.document.querySelector(selector);
  // }
}
