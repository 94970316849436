import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { catchError, filter, finalize, map, share, take, takeUntil } from 'rxjs/operators';
import { RegistrationLoader } from 'src/app/core/loaders/registration.loader';
import { IAddressDetails, TBillingPeriod, TPlanType } from 'src/app/core/models/i-order-state.model';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { RecurlyValidatorsService } from 'src/app/shared/components/recurly/services/recurly-validators.service';
import { IRecurlyError } from 'src/app/shared/components/recurly/types/recurly-error';
import { VendorInfoLoader } from '../../../../core/loaders/vendor-info.loader';
import { DOCUMENT } from '@angular/common';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ICaptchaInformationReq, IRegistrationPostResDto } from 'src/app/core/endpoints/registrations.endpoints';
import { ConversionsApi } from 'src/app/core/loaders/conversion-api.loader';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TokenPayload } from '@recurly/recurly-js';
import { ModalService } from 'src/app/core/service/modal-state.service';
import { planDeviceImg, planTypeName } from 'src/app/utils/string';

@Component({
  templateUrl: './order-single-step.component.html',
  styleUrls: ['./order-single-step.component.scss'],
  providers: [RecurlyValidatorsService],
})
export class OrderSingleStepComponent implements OnInit, OnDestroy, AfterViewChecked {
  private static readonly CAPTCHA_ACTION_NEW_ORDER = 'NewForceOrder';
  private static readonly DEFAULT_TRANSACTION_ERROR =
    'An error occurred with your order. Please check your order details and try again or contact our support.';

  public counter$: Observable<number> = of(0);
  public reCaptchaFailed = false;
  public retryCount = 10;
  public isLoading = false;
  public emailTabSubj$: Subject<boolean> = new Subject<boolean>();
  public phoneTabSubj$: Subject<boolean> = new Subject<boolean>();

  public get planType(): TPlanType {
    return this.orderState.planType;
  }

  public get planTypeName(): string {
    return planTypeName(this.planType);
  }

  public get planDeviceImg(): string {
    return planDeviceImg(this.planType);
  }

  public get isReview(): boolean {
    return this.orderState.stateValue().isReview;
  }

  public get isVendorSpecific(): boolean {
    return this.orderState.isVendorSpecific;
  }

  public get isHybridVendorPlan(): boolean {
    return this.orderState.isHybridVendorPlan;
  }

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public orderState: OrderStateService,
    private registrationLoader: RegistrationLoader,
    private vendorInfoLoader: VendorInfoLoader,
    private recurlyValidators: RecurlyValidatorsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
    private conversionsApiLoader: ConversionsApi,
    private gtmService: GoogleTagManagerService,
    private modalService: ModalService,
  ) {}

  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public ngOnInit(): void {
    if (this.orderState.isOrderCompleted()) {
      this.orderState.resetState();
    }

    this.orderState.recurlyToken$.pipe(takeUntil(this.destroy$)).subscribe((token) => this.onPayPressed(token));

    this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('promo-code')) {
        this.orderState.applyPromoCode(queryParamMap.get('promo-code') as string);
      }

      if (queryParamMap.has('vfc')) {
        const vendorFulfillmentCode = queryParamMap.get('vfc') as string;
        this.isLoading = true;
        combineLatest([this.vendorInfoLoader.loadVendorInfo(vendorFulfillmentCode), this.orderState.state()])
          .pipe(
            filter(([, state]) => state.planDataLoaded),
            take(1),
            finalize(() => (this.isLoading = false)),
            map(([vendorCodeData]) => vendorCodeData),
          )
          .subscribe({
            next: (vendorCodeData) => {
              this.orderState.updateState({ vendorCodeData, vendorFulfillmentCode });
            },
            error: (e) => {
              this.orderState.markVendorCodeError();
              this.orderState.setErrorTransactionMsg(
                'An error occurred while loading your vendor code. Please check your link again.',
              );
              console.error('Loading vendor specific info failed', e);
            },
          });
      }

      if (queryParamMap.has('redirect')) {
        const redirect = queryParamMap.get('redirect') as string;
        this.orderState.updateState({ redirect });
      }

      if (queryParamMap.has('billing')) {
        const billingPeriod = queryParamMap.get('billing') as TBillingPeriod;
        const cycles = ['annual', 'monthly'] as TBillingPeriod[];

        if (cycles.includes(billingPeriod)) {
          this.orderState.setBillingPeriod(billingPeriod);
        }
      }
    });
  }

  public pushGtmOnOrderComplete(): void {
    const gtmTag = {
      event: 'trial_form_submission',
      number_of_vehicles: Number(this.orderState.devicesCount()),
      ordered_plan_type: this.orderState.subscriptionPlanCode(),
    };
    this.gtmService.pushTag(gtmTag);
  }

  public get vendorFulfillmentCode(): string | undefined {
    return this.orderState.stateValue().vendorFulfillmentCode;
  }

  public toggleReview(): void {
    this.orderState.toggleReview();
  }

  public async onPayPressed(recurlyToken: TokenPayload): Promise<void> {
    try {
      const { email, phone } = this.orderState.getShippingAddress() as IAddressDetails;
      this.orderState.setCommonDetails({
        email,
        phone,
        howDidYouFindUs: '',
        howDidYouFindUsComment: '',
      });
      this.isLoading = true;

      const promoCode = this.orderState.promoCodeData();
      const couponCode = (promoCode && promoCode.type !== 'invalid' && promoCode.couponCode) || '';

      this.createConversionsApiEvent(email, phone);

      this.generateReCaptchaToken()
        .pipe(take(1))
        .subscribe((recaptchaToken: string) => {
          if (!recaptchaToken) {
            this.isLoading = false;
            this.startRetryCounter();
            return;
          }
          this.registrationLoader
            .register(
              (recurlyToken as any).id,
              couponCode,
              email || '',
              `1${phone}`,
              this.document.referrer || '',
              this.orderState.getShippingAddress()?.companyName || '',
              this.orderState.devicesCount() || 0,
              this.orderState.getShippingAddress() as IAddressDetails,
              this.orderState.getBillingAddress() as IAddressDetails,
              this.vendorFulfillmentCode,
              this.orderState.getProductsList(),
              this.orderState.subscriptionPlanCode(),
              {
                token: recaptchaToken,
                action: OrderSingleStepComponent.CAPTCHA_ACTION_NEW_ORDER,
              } as ICaptchaInformationReq,
            )
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
              next: (res: IRegistrationPostResDto) => {
                // let user retry if reCAPTCHA fails backend validation
                if (
                  res?.captchaInformation &&
                  res?.captchaInformation?.action === OrderSingleStepComponent.CAPTCHA_ACTION_NEW_ORDER &&
                  !res?.captchaInformation?.isValid
                ) {
                  this.startRetryCounter();
                  return;
                }
                this.orderState.markOrderAsCompleted();
                this.pushGtmOnOrderComplete();
                const redirectUrl = this.orderState.getRedirectAddress();
                const fallbackUrlSuffix = Number(this.orderState.devicesCount()) === 1 ? '/singlevehicle' : '';
                if (!!redirectUrl && !!this.vendorFulfillmentCode) {
                  this.redirect(redirectUrl, `/shipping/payment/congratulation${fallbackUrlSuffix}`);
                } else {
                  this.router.navigateByUrl(`/shipping/payment/congratulation${fallbackUrlSuffix}`);
                }
              },
              error: (e) => {
                console.error('register failed', e);
                const res = e.error as IRegistrationPostResDto;
                const errorMessage =
                  res?.paymentTransactionStatus?.message ?? OrderSingleStepComponent.DEFAULT_TRANSACTION_ERROR;
                this.orderState.setErrorTransactionMsg(errorMessage);
                this.modalService.openModal('popup-message-modal');
              },
            });
        });
    } catch (e) {
      this.isLoading = false;
      console.error('Error: getToken', e);
      this.handleRecurlyError(e as IRecurlyError);
    }
  }

  public redirect(url: string = '', fallbackRoute: string = ''): void {
    if (!url) {
      this.router.navigateByUrl(fallbackRoute);
    } else {
      const urlToRedirect = /^http(s)?:\/\//.test(url) ? url : `https://${url}`;
      try {
        this.document.location.href = urlToRedirect;
      } catch (e) {
        this.router.navigateByUrl(fallbackRoute);
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateReCaptchaToken(): Observable<string> {
    return this.recaptchaV3Service.execute(OrderSingleStepComponent.CAPTCHA_ACTION_NEW_ORDER).pipe(
      take(1),
      map((token: string) => token),
      catchError((error) => {
        console.error(`ReCaptcha error generating token:`, error);
        return of('');
      }),
    );
  }

  private startRetryCounter(): void {
    this.reCaptchaFailed = true;
    this.retryCount = 10;
    this.counter$ = timer(0, this.retryCount * 100).pipe(
      take(this.retryCount),
      map(() => {
        if (this.retryCount <= 1) {
          this.reCaptchaFailed = false;
        }
        return --this.retryCount;
      }),
      share(),
    );
  }

  private handleRecurlyError(error: IRecurlyError): void {
    const address = this.orderState.getBillingAddress();
    if (
      this.recurlyValidators.handleError(error, {
        first_name: address?.firstName,
        last_name: address?.lastName,
        city: address?.city,
        country: address?.country,
        state: address?.state,
        postal_code: address?.postalCode,
        address1: address?.address1,
        address2: address?.address2,
      })
    ) {
    } else {
      this.orderState.setErrorTransactionMsg(OrderSingleStepComponent.DEFAULT_TRANSACTION_ERROR);
    }
  }

  private createConversionsApiEvent(email: string, phone: string): void {
    this.conversionsApiLoader
      .createEvent(email, phone)
      .pipe(take(1))
      .subscribe({
        error: (err) => console.error(err),
      });
  }
}
