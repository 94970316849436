import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const PORTAL_ID = '20075576';
const FORM_ID = 'c3359778-e42e-4258-9aef-d72899256a5b';
const HUBSPOT_FORM_SUBMIT_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`;

export interface IFormFieldData {
  objectTypeId: string;
  name: string;
  value: string;
}

export interface IHubspotFormDto {
  fields: IFormFieldData[];
  context: {
    pageName: string;
    pageUri: string;
    hutk: string;
    ipAddress?: string;
  };
}

@Injectable({ providedIn: 'root' })
export class HubSpotEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public submitForm(formData: IHubspotFormDto): Observable<void> {
    return this.httpClient.post<void>(HUBSPOT_FORM_SUBMIT_URL, formData);
  }
}
