import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { IConversionsApiPost, IConversionsApiRes } from '../endpoints/meta.endpoints';
import { MetaEndpoints } from '../endpoints/meta.endpoints';
import { encryptSha256 } from 'src/app/utils/string';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConversionsApi {
  constructor(private endpoints: MetaEndpoints) {}

  public createEvent(email: string, phone: string): Observable<IConversionsApiRes> {
    const testEventCode = environment.production ? undefined : 'TEST31242';

    return forkJoin([encryptSha256(email), encryptSha256(phone)]).pipe(
      switchMap(([encryptedEmail, encryptedPhone]) => {
        const gmtTimestamp = new Date().toUTCString();
        const gmtSeconds = Date.parse(gmtTimestamp) / 1000;

        return this.endpoints.conversionsApiEvent({
          data: [
            {
              event_name: 'Get',
              event_time: gmtSeconds,
              event_source_url: window.location.hostname,
              action_source: 'website',
              user_data: {
                em: [encryptedEmail],
                ph: [encryptedPhone],
              },
            },
          ],
          ...(testEventCode && { test_event_code: testEventCode }),
        } as IConversionsApiPost);
      }),
    );
  }
}
