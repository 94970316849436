import { Directive, Host, HostListener, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
  selector: '[appMarkTouchedIfLengthGreaterThan]',
})
export class MarkTouchedIfLengthGreaterThanDirective {
  @Input()
  public appMarkTouchedIfLengthGreaterThan = 0;

  @Input()
  public formControlName: string | undefined;

  constructor(@Optional() @Host() @SkipSelf() private parent: ControlContainer) {}

  @HostListener('input')
  public onInput(): void {
    const control =
      this.formControlName && this.parent && this.parent.control && this.parent.control.get(this.formControlName);
    if (
      control &&
      !control.touched &&
      control.value &&
      control.value.toString().length > this.appMarkTouchedIfLengthGreaterThan
    ) {
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  }
}
