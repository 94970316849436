<div class="common-layout">
  <div class="logo-layout-sm sm-show">
    <template *ngTemplateOutlet="logo"></template>
  </div>
  <div class="header">
    <div class="content">
      <template *ngTemplateOutlet="logo"></template>
      <div>
        <h2 class="title">Your order</h2>
        <h3 class="sub-title">30-Day Money Back Guarantee. No Contracts. Free Shipping.</h3>
      </div>
      <a class="phone" href="tel:806-855-8255">806-855-8255</a>
    </div>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #logo>
  <a target="_self" href="https://forcefleettracking.com/">
    <img class="logo" src="/assets/icons/logo.svg" />
  </a>
</ng-template>
