<app-common-layout>
  <div class="content-container">
    <div class="content-wrapper">
      <h1 *ngIf="!isVendorSpecific; else vendorSpecificTpl" class="title">
        Start your risk free {{ orderState.trialDaysCount() }}-day trial.
      </h1>
      <ng-template #vendorSpecificTpl>
        <h1 class="title smaller-fs">Sign up to access your vehicle data</h1>
      </ng-template>
      <div class="vwo-sub-title">
        <b>
          We'll ship you GPS devices for each one of your vehicles, so you can try Force Fleet Tracking risk-free.
        </b>
      </div>
      <app-faq-tooltip></app-faq-tooltip>

      <div class="need-help-sm">Need help? <a class="phone" href="tel:806-855-8255">806-855-8255</a></div>
      <div class="content">
        <div class="left-side">
          <h2 class="content-title">
            <ng-content select="page-title"></ng-content>
          </h2>
          <ng-content></ng-content>
        </div>
        <div class="right-side">
          <div class="order-summary-container">
            <div class="dots-background"></div>
            <div class="gray-background"></div>
            <div class="order-summary-wrapper">
              <app-order-payment></app-order-payment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-common-layout>
