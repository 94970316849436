<div class="input-quantity">
  <cds-icon
    (click)="onOperatorClicked('minus')"
    [class.disabled]="operatorDisabled('minus')"
    shape="minus-circle"
    size="18"
  ></cds-icon>
  <input
    [value]="quantity"
    (change)="onInputValChanged($event)"
    type="number"
    [min]="min"
    [max]="max"
    oninput="validity.valid||(value='');"
    [disabled]="disabled"
    [class.warning]="inputInvalid"
  />
  <cds-icon
    (click)="onOperatorClicked('add')"
    [class.disabled]="operatorDisabled('add')"
    shape="plus-circle"
    size="18"
  ></cds-icon>
</div>
