import { Component, ElementRef, forwardRef, HostBinding, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

/* tslint-disable no-unused-expressions */

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('inputRef', { static: true })
  public inputRef!: ElementRef<HTMLSelectElement>;

  @Input()
  public disabled = false;

  @Input()
  public readonly = false;

  @Input()
  public name = '';

  @Input()
  public cssClass = '';

  @HostBinding('class.app-select')
  public hostClass = true;

  public value: any;
  public isOpen = false;

  private control!: NgControl;
  private onChangeFn!: (value: any) => void;
  private onTouchFn!: () => void;

  constructor(private injector: Injector) {}

  @HostBinding('class.has-error')
  public get hasError(): boolean {
    return !!this.control?.touched && !!this.control?.invalid;
  }

  public ngOnInit(): void {
    this.control = this.injector.get(NgControl);
  }

  public writeValue(value: any): void {
    this.inputRef.nativeElement.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChangeHandler(e: any): void {
    if (this.onChangeFn) {
      this.onChangeFn(e.target.value);
    }
    this.isOpen = false;
  }

  public onBlurHandler(): void {
    if (this.onTouchFn) {
      this.onTouchFn();
    }
  }
}
