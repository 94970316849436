import { Injectable, NgZone } from '@angular/core';
import { Recurly } from '@recurly/recurly-js';
import { Observable } from 'rxjs';
import { RecurlyScriptLoader } from './recurly-script-loader';

/*
    recurly.tax({
        tax_code: 'digital',
        postal_code: '904',
        country: 'US'
      }, (...args) => console.log('res', ...args));

      recurly.coupon({
        coupon: 'discount10', plans:['fleet_monthly_1'], planType: 'fleet_monthly_1', currency:'USD'
      }, (...args) => console.log('res', ...args))


      recurly.planType('fleet_monthly_1', (...args) => console.log(...args))

      recurly.planType('fleet_annual_1', (...args) => console.log(...args))
    */

export interface ITaxesRate {
  rate: number; // example 0.1025
  region: string; // state code example: 'CA' - california
}

export interface IRecurlyPlan {
  trial?: {
    interval: 'days';
    length: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class RecurlyService {
  constructor(private recurlyScriptLoader: RecurlyScriptLoader, private zone: NgZone) {}

  public loadTaxesRate(postalCode: string, countryCode: string): Observable<ITaxesRate | null> {
    return this.wrapInObservable<ITaxesRate | null, any[]>(
      (recurly, done) => {
        recurly.tax(
          {
            tax_code: 'digital',
            postal_code: postalCode,
            country: countryCode,
          },
          done,
        );
      },
      (ratesList) => {
        if (Array.isArray(ratesList) && ratesList.length) {
          const [rate] = ratesList;
          return {
            rate: parseFloat(rate.rate),
            region: rate.region,
          };
        }
        return null;
      },
    );
  }

  public loadPlan(subscriptionPlan: string): Observable<IRecurlyPlan> {
    return this.wrapInObservable<IRecurlyPlan, any>(
      (recurly, done) => recurly.planType(subscriptionPlan, done),
      (data) => ({ trial: data?.trial }),
    );
  }

  private async loadRecurly(): Promise<Recurly> {
    return await this.recurlyScriptLoader.load();
  }

  private wrapInObservable<R, T>(
    requestFn: (recurly: any, callback: (err: any, data: T) => void) => void,
    transformResponse: (data: T) => R,
  ): Observable<R> {
    return new Observable<R>((subscriber) => {
      this.loadRecurly()
        .then((recurly: any) => {
          requestFn(recurly, (err: any, data: T) => {
            this.zone.run(() => {
              if (err) {
                subscriber.error(err);
              } else {
                subscriber.next(transformResponse(data));
                subscriber.complete();
              }
            });
          });
        })
        .catch((e) => this.zone.run(() => subscriber.error(e)));
    });
  }
}
