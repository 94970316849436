<span
  class="faq-tooltip-wrapper"
  contentClass="faq-tooltip"
  [position]="'right-bottom'"
  [mdUpWidth]="405"
  [mdDownWidth]="312"
  [hint]="faqTplVWO"
>
  <span class="faq-link">Questions? See our Free Trial FAQ</span>
  <ng-template #faqTplVWO>
    <p class="tooltip-title">Free Trial FAQ</p>
    <p class="tooltip-sub-title">Do I have to pay for anything during the trial?</p>
    <p class="tooltip-text">
      No! We’ll ship you GPS tracking devices for each one of your vehicles, free of charge. You’ve got
      {{ orderState.trialDaysCount() }} days to try our service to see if it’s right for your business.
    </p>
    <p class="tooltip-sub-title">What happens after the {{ orderState.trialDaysCount() }}-day free trial period?</p>
    <p class="tooltip-text">
      Your credit card will be charged on your first billing date if you'd like to stay on as a Force Fleet Tracking
      customer after your free trial period is over. There are no actions required from your end.
    </p>
    <p class="tooltip-sub-title">What happens if I want to discontinue my free trial?</p>
    <p class="tooltip-text">
      No problem. Just contact us by email or phone to let us know. Just return the GPS tracking devices to us. Return
      shipping is free. If we don’t receive them back within {{ orderState.trialDaysCount() }} days from when you
      cancel, we will charge your credit card for the value of the devices ($150 each).
    </p>
  </ng-template>
</span>
