import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  HD_CABLE_INFO_MAP,
  IHeavyDutyCableInfo,
  TBillingPeriod,
  TForcePlanType,
  TPlanType,
} from 'src/app/core/models/i-order-state.model';
import { TPromoCodeModel } from 'src/app/core/models/ipromo-code.model';
import { ModalService } from 'src/app/core/service/modal-state.service';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { InputPromoComponent } from '../input-promo/input-promo.component';
import { planDeviceImg, planTypeName } from 'src/app/utils/string';

@Component({
  selector: 'app-order-plan-details',
  templateUrl: './order-plan-details.component.html',
  styleUrls: ['./order-plan-details.component.scss'],
})
export class OrderPlanDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(InputPromoComponent, { static: true })
  public inputPromo!: InputPromoComponent;

  @Input()
  public isVendorSpecific = false;

  @Input()
  public sectionPlanType: TForcePlanType;

  public displayPromoCodeInput = false;
  public planType: TPlanType;

  private subscription = new Subscription();

  public get billingPeriod(): TBillingPeriod {
    return this.orderState.billingPeriod();
  }

  public get promoCode(): TPromoCodeModel | null {
    return this.orderState.promoCodeData();
  }

  public get isReview(): boolean {
    return this.orderState.stateValue().isReview;
  }

  public get maxOrderQuantity(): number {
    return this.planType === 'gpsStarter' ? 3 : 50;
  }

  public get showHdcOption(): boolean {
    return this.planType !== 'trakViewPro' && !this.isVendorSpecific;
  }

  public get planTypeName(): string {
    return planTypeName(this.planType);
  }

  public get planDeviceImg(): string {
    return planDeviceImg(this.planType);
  }

  public get oneVehicleTrackingPrice(): number {
    return this.orderState.oneVehicleTrackingPrice(this.sectionPlanType);
  }

  public get oneDevicePrice(): number {
    return this.orderState.oneDevicePrice(this.sectionPlanType);
  }

  public get devicesCount(): number {
    if (this.isVendorSpecific) {
      if (this.planType === 'trakViewPro') {
        return this.orderState.getNumberOfVendorDashcams();
      }
      if (this.planType === 'gpsPremium') {
        return this.orderState.getNumberOfVendorTrackers();
      }
    }
    return this.orderState.devicesCount();
  }

  constructor(public orderState: OrderStateService, public modalService: ModalService) {
    this.planType = this.orderState.stateValue().planType;
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.orderState
        .state()
        .pipe(
          map(({ promoCodeData }) => promoCodeData),
          filter((code) => !!code && code.type !== 'invalid'),
          distinctUntilChanged(),
        )
        .subscribe(() => this.inputPromo?.reset()),
    );
  }

  public ngOnChanges(sc: SimpleChanges): void {
    if (sc?.sectionPlanType) {
      this.planType = sc.sectionPlanType.currentValue;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onApplyPromo(promoCode: string): void {
    this.orderState.applyPromoCode(promoCode);
  }

  public onBillingPeriodChanged(isYearlyChecked: boolean): void {
    this.orderState.setBillingPeriod(isYearlyChecked ? 'annual' : 'monthly');
  }

  public onBillingPeriodSwitch(billingPeriod: TBillingPeriod): void {
    this.orderState.setBillingPeriod(billingPeriod);
  }

  public onDeviceCountChanged(deviceCount: number): void {
    this.orderState.setDevicesCount(deviceCount);
  }

  public toggleHaveBigTrucks(): void {
    this.orderState.toggleHaveBigTrucks();
  }

  public getCableInfo(productCode: string): IHeavyDutyCableInfo {
    return HD_CABLE_INFO_MAP[productCode];
  }
}
