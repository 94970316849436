import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HintDirective } from './hint.directive';
import { HintComponent } from './components/hint/hint.component';
import { WINDOW_PROVIDERS } from './services/window.service';
import { BackgroundLayerComponent } from './components/background-layer/background-layer.component';

@NgModule({
  declarations: [HintDirective, HintComponent, BackgroundLayerComponent],
  imports: [CommonModule],
  exports: [HintDirective],
  entryComponents: [HintComponent, BackgroundLayerComponent],
})
export class HintModule {
  static forRoot(): ModuleWithProviders<HintModule> {
    return {
      ngModule: HintModule,
      providers: [WINDOW_PROVIDERS],
    };
  }
}
