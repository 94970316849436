import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, timer } from 'rxjs';
import { buffer, debounceTime, filter, first, takeUntil } from 'rxjs/operators';

import { WINDOW } from './services/window.service';
import { TContentType, THintType, TPosition, TTrigger } from './models/hint-types';
import { IAppendedComponentParameters } from './models/iappended-component-parameters';
import { IAppendedComponentData } from './models/iappended-component-data';
import { HintComponent } from './components/hint/hint.component';
import { IHintEvent } from './models/ihint-event';
import { BackgroundLayerComponent } from './components/background-layer/background-layer.component';
import { breakPointsConfig } from '../../../core/config/breakpoints.config';

const HOVER_DEBOUNCE_TIMEOUT = 50;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hint]',
  exportAs: 'hint',
})
export class HintDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input('hint')
  public content: any = '';

  @Input()
  public contentClass = '';

  @Input()
  public fixedHintType: THintType | undefined;

  @Input()
  public position: TPosition = 'top-left';

  @Input()
  public closeOnClickContent = true;

  @Input()
  public closeOnScroll = true;

  @Input()
  public autoPositioning = true;

  @Input()
  public addAfterParent = false;

  @Input()
  public showDelay = 50;

  @Input()
  public hideDelay = 300;

  @Input()
  public tipOffset = 8;

  @Input()
  public shiftX = 0;

  @Input()
  public shiftY = 0;

  @Input()
  public mdUpWidth = 312;

  @Input()
  public mdDownWidth = 312;

  @Input()
  public maxWidth = 200;

  @Input()
  public keepOpenOnHoverOverContent = false;

  @Input()
  public contentChangedTimeStamp: number | undefined;

  get isDisplayOnHover(): boolean {
    return this.trigger === 'hover';
  }

  get isDestroyed(): boolean {
    return !!this.componentRef && this.componentRef.hostView.destroyed;
  }

  @Output()
  public events: EventEmitter<IHintEvent> = new EventEmitter<IHintEvent>();

  @Output()
  public openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public bgLayerComponentRef: ComponentRef<any> | undefined;
  public componentRef: ComponentRef<any> | undefined;

  private hintType: THintType | undefined;
  private trigger: TTrigger | undefined;
  private width: number | undefined;

  private isOpen = false;

  private readonly onDestroyElement$: Subject<void> = new Subject<void>();
  private readonly onStopHiding$: Subject<void> = new Subject<void>();
  private readonly onScrollContent$: Subject<void> = new Subject<void>();
  private readonly onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private elementRef: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  @HostListener('focusin')
  @HostListener('mouseenter')
  public onMouseEnterHandler(): void {
    if (this.isDisplayOnHover) {
      this.show();
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeaveHandler(): void {
    if (this.isDisplayOnHover) {
      timer(this.hideDelay)
        .pipe(takeUntil(this.onStopHiding$ || this.onDestroyElement$ || this.onDestroy$))
        .subscribe(() => {
          this.hide();
        });
    }
  }

  @HostListener('click')
  public onClickHandler(): void {
    if (this.trigger === 'hover') {
      return;
    }
    if (!this.isOpen) {
      this.show();
    } else {
      if (this.hintType === 'modal') {
        return;
      }
      this.hide();
    }
  }

  @HostListener('window:click', ['$event'])
  public onClickOutside(event: any): void {
    const isElementClicked = this.elementRef.nativeElement.contains(event.target);
    if (!isElementClicked) {
      const isTimestampsEqual = this.contentChangedTimeStamp === event.timeStamp;
      const isChildClicked =
        this.componentRef && this.componentRef.instance && this.componentRef.instance.elementRef
          ? this.componentRef.instance.elementRef.nativeElement.contains(event.target) || isTimestampsEqual
          : false;
      if (isChildClicked && this.trigger === 'hover' && this.keepOpenOnHoverOverContent) {
        return;
      }
      if ((this.isOpen && !isChildClicked) || (this.closeOnClickContent && isChildClicked)) {
        if (this.hintType === 'modal') {
          return;
        }
        this.hide();
      }
    }
  }

  public ngOnInit(): void {
    this.setGeometryParams();

    this.onDestroyElement$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.hide();
    });
    this.addCapturingListeners();

    this.onScrollContent$
      .pipe(
        buffer(this.onScrollContent$.pipe(debounceTime(100))),
        filter((arr) => arr.length > 1),
        takeUntil(this.onDestroy$),
      )
      .subscribe(() => {
        this.handlePositionChanged();
      });
  }

  public ngOnDestroy(): void {
    this.onDestroyElement$.next();
    this.removeCapturingListeners();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public show(): void {
    this.isOpen = true;
    this.openChange.emit(this.isOpen);
    if (!this.componentRef || this.isDestroyed) {
      this.create();
    } else {
      this.showElement();
    }
  }

  public hide(): void {
    this.isOpen = false;
    this.openChange.emit(this.isOpen);

    if (!this.isDestroyed) {
      this.hideElement();

      if (this.bgLayerComponentRef) {
        this.appRef.detachView(this.bgLayerComponentRef.hostView);
        this.bgLayerComponentRef.destroy();
      }

      if (this.componentRef) {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.events.emit({
          type: 'hidden',
          position: this.getElementPosition(),
        });
      }
    }
  }

  public handleContentChanged(): void {
    if (this.componentRef && this.componentRef.instance) {
      this.componentRef.instance.resetContentPosition();
    }
  }

  public handlePositionChanged(): void {
    if (this.componentRef && this.componentRef.instance) {
      if (this.isElementInViewport() || this.hintType === 'modal') {
        this.componentRef.instance.setTopLeftCoords();
      } else {
        this.hide();
      }
    }
  }

  private setGeometryParams(): void {
    this.hintType = this.getHintType();
    this.trigger = this.getTriggerType();
    const mdDownWidth =
      document.documentElement.clientWidth > this.mdDownWidth ? this.mdDownWidth : document.documentElement.clientWidth;
    this.width = matchMedia(`(max-width: ${breakPointsConfig.md}px)`).matches ? mdDownWidth : this.mdUpWidth;
  }

  private getHintType(): THintType {
    if (!this.fixedHintType) {
      return matchMedia(`(max-width: ${breakPointsConfig.md}px) and (hover: none)`).matches ? 'modal' : 'tooltip';
    }
    return this.fixedHintType;
  }

  private getTriggerType(): TTrigger {
    return matchMedia('(hover: hover)').matches ? 'hover' : 'click';
  }

  private isElementInViewport(): boolean {
    let element = this.elementRef.nativeElement;
    const rect = element.getBoundingClientRect();

    let isVisibleInParents =
      rect.top < (this.window.innerHeight || this.document.documentElement.clientHeight) &&
      rect.right > 0 &&
      rect.bottom > 0 &&
      rect.left < (this.window.innerWidth || this.document.documentElement.clientWidth);

    while (!!element.offsetParent) {
      element = element.offsetParent;
      const offsetParentRect = element.getBoundingClientRect();
      isVisibleInParents =
        isVisibleInParents &&
        rect.top < offsetParentRect.bottom &&
        rect.right > offsetParentRect.left &&
        rect.bottom > offsetParentRect.top &&
        rect.left < offsetParentRect.right;
    }

    return isVisibleInParents;
  }

  private getInstanceData(): IAppendedComponentData {
    return {
      content: this.content,
      contentType: this.getContentType(),
      contentClass: this.contentClass,
      contentPosition: this.position,
      element: this.elementRef.nativeElement,
      elementPosition: this.getElementPosition(),
      hintType: this.hintType,
      tipOffset: this.tipOffset,
      shiftX: this.shiftX,
      shiftY: this.shiftY,
      width: this.width,
      maxWidth: this.maxWidth,
      showDelay: this.trigger === 'hover' ? this.showDelay + HOVER_DEBOUNCE_TIMEOUT : this.showDelay,
      autoPositioning: this.autoPositioning,
      isAddedAfterParent: this.addAfterParent,
      closeOnLeave: this.keepOpenOnHoverOverContent && this.trigger === 'hover',
    };
  }

  private create(): void {
    if (this.hintType === 'modal') {
      this.bgLayerComponentRef = this.componentFactoryResolver
        .resolveComponentFactory(BackgroundLayerComponent)
        .create(this.injector);
      this.appRef.attachView(this.bgLayerComponentRef.hostView);
      const bgLayerDomElement = (this.bgLayerComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.document.body.appendChild(bgLayerDomElement);
    }

    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(HintComponent).create(this.injector);
    (this.componentRef.instance as IAppendedComponentParameters).data = this.getInstanceData();

    this.appRef.attachView(this.componentRef.hostView);
    const domElement = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    if (this.addAfterParent) {
      this.elementRef.nativeElement.parentNode.insertBefore(domElement, this.elementRef.nativeElement.nextSibling);
    } else {
      this.document.body.appendChild(domElement);
    }

    this.events.emit({
      type: 'show',
      position: this.getElementPosition(),
    });
    (this.componentRef.instance as IAppendedComponentParameters).events
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: any) => this.handleEvents(event));
    (this.componentRef.instance as IAppendedComponentParameters).onclose
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.hide());
  }

  private showElement(): void {
    if (!this.isDestroyed) {
      (this.componentRef?.instance as IAppendedComponentParameters).show = true;
      this.events.emit({
        type: 'show',
        position: this.getElementPosition(),
      });
    }
  }

  private hideElement(): void {
    if (!this.componentRef || this.isDestroyed) {
      return;
    }
    (this.componentRef.instance as IAppendedComponentParameters).show = false;
    this.events.emit({
      type: 'hide',
      position: this.getElementPosition(),
    });
  }

  private handleEvents(event: IHintEvent): void {
    if (event.type === 'shown') {
      this.events.emit({
        type: 'shown',
        position: this.getElementPosition(),
      });
    }

    if (event.type === 'entered') {
      this.onStopHiding$.next();
    }
  }

  private getElementPosition(): DOMRect {
    return this.elementRef.nativeElement.getBoundingClientRect();
  }

  private getContentType(): TContentType {
    return this.content instanceof TemplateRef ? 'template' : 'stringHtml';
  }

  private onResize = (event: Event) => {
    timer(100)
      .pipe(first())
      .subscribe(() => {
        this.setGeometryParams();
      });
    this.fireOnDestroyEvent(event);
  };

  private fireOnDestroyEvent = (event: Event) => {
    if (this.hintType !== 'modal') {
      const isChildScrolled =
        event.target !== this.window &&
        this.componentRef &&
        this.componentRef.instance &&
        this.componentRef.instance.elementRef
          ? this.componentRef.instance.elementRef.nativeElement.contains(event.target)
          : false;
      if ((event.type === 'resize' && event.target === this.window) || (event.type === 'scroll' && !isChildScrolled)) {
        this.onDestroyElement$.next();
      }
    } else {
      this.fireOnScrollEvent();
    }
  };

  private fireOnScrollEvent = () => {
    this.onScrollContent$.next();
  };

  private addCapturingListeners(): void {
    if (!this.addAfterParent) {
      this.window.addEventListener('resize', this.onResize, true);
      this.window.addEventListener(
        'scroll',
        this.closeOnScroll ? this.fireOnDestroyEvent : this.fireOnScrollEvent,
        true,
      );
    }
  }

  private removeCapturingListeners(): void {
    if (!this.addAfterParent) {
      window.removeEventListener('resize', this.onResize, true);
      window.removeEventListener('scroll', this.closeOnScroll ? this.fireOnDestroyEvent : this.fireOnScrollEvent, true);
    }
  }
}
