import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { InputService } from './input.service';

@Directive({
  selector: '[appInputCtrl]',
})
export class InputCtrlDirective implements OnInit, OnDestroy {
  private disabledObserver!: MutationObserver;

  constructor(
    el: ElementRef,
    @Self()
    @Optional()
    private control: NgControl,
    @Optional()
    private inputService: InputService,
  ) {
    this.disabledObserver = new MutationObserver(([record]) => {
      if (record.attributeName === 'disabled' && record.target) {
        this.inputService.isDisabled = (record.target as HTMLInputElement).disabled;
      }
    });

    this.disabledObserver.observe(el.nativeElement, {
      attributeFilter: ['disabled'],
      attributes: true,
      childList: false,
      subtree: false,
    });
  }

  public ngOnInit(): void {
    if (this.inputService) {
      this.inputService.setControl(this.control);
    }
  }

  public ngOnDestroy(): void {
    if (this.disabledObserver) {
      this.disabledObserver.disconnect();
    }
  }

  @HostListener('focus')
  public onFocus(): void {
    if (this.inputService) {
      this.inputService.isFocused = true;
    }
  }

  @HostListener('blur')
  public onBlur(): void {
    if (this.inputService) {
      this.inputService.isFocused = false;
    }
  }
}
