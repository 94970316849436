<div class="shipping-details">
  <form [formGroup]="orderDetailsForm">
    <ng-container *ngIf="isShippingForm">
      <h2 class="section-header">Company Details*</h2>

      <app-input-wrapper class="input-wrapper">
        <input
          #companyName
          appInputCtrl
          [appMarkTouchedIfLengthGreaterThan]="maxLengthList.companyName"
          formControlName="companyName"
          [maxlength]="maxLengthList.companyName"
          class="app-input"
          type="text"
          name="organization"
          [placeholder]="placeholders.companyName"
        />
        <div class="input-error">
          <div appFormError="companyName" errorName="required">Please provide company name.</div>
          <div appFormError="companyName" errorName="maxlength">Company name is too long (maximum 100 characters).</div>
        </div>
      </app-input-wrapper>

      <app-input-wrapper class="input-wrapper">
        <input
          #emailInput
          appInputCtrl
          name="email"
          class="app-input"
          formControlName="email"
          type="email"
          placeholder="Email"
          (keydown.tab)="onHandleEmailTabPressed($event)"
        />
        <div *ngIf="orderDetailsForm.get('email')?.status !== 'PENDING'; else validatingEmailTpl" class="input-error">
          <div appFormError="email" errorName="required">Please provide a valid email address.</div>
          <div appFormError="email" errorName="email">Please provide a valid email address.</div>
          <div appFormError="email" errorName="emailAsync">
            {{ orderDetailsForm.get('email')?.errors?.emailAsync }}
          </div>
        </div>
        <ng-template #validatingEmailTpl>
          <div class="input-validation-in-progress">
            Validating email address...
          </div>
        </ng-template>
      </app-input-wrapper>

      <p class="email-tooltip">This email will be used to create your admin account.</p>
    </ng-container>

    <h2 class="section-header">{{ isShippingForm ? 'Shipping Information*' : 'Billing Address*' }}</h2>

    <ng-container *ngIf="!isShippingForm">
      <p class="country-select-label">Country/Region</p>

      <app-select [readonly]="true" formControlName="country" class="app-select">
        <option selected value="US">United States</option>
      </app-select>
    </ng-container>

    <div class="name-inputs-wrapper">
      <app-input-wrapper class="input-wrapper">
        <input
          #firstNameInput
          appInputCtrl
          [appMarkTouchedIfLengthGreaterThan]="maxLengthList.firstName"
          formControlName="firstName"
          class="app-input"
          type="text"
          name="given-name"
          [placeholder]="placeholders.firstName"
        />
        <div class="input-error">
          <div appFormError="firstName" errorName="required">Please provide a first name.</div>
          <div appFormError="firstName" errorName="maxlength">Limit is 50 characters.</div>
          <div appFormError="firstName" errorName="recurly">
            First name {{ orderDetailsForm.get('firstName')?.errors?.recurly }}
          </div>
        </div>
      </app-input-wrapper>

      <app-input-wrapper class="input-wrapper">
        <input
          appInputCtrl
          [appMarkTouchedIfLengthGreaterThan]="maxLengthList.lastName"
          formControlName="lastName"
          class="app-input"
          type="text"
          name="lname"
          [placeholder]="placeholders.lastName"
        />
        <div class="input-error">
          <div appFormError="lastName" errorName="required">Please provide a last name.</div>
          <div appFormError="lastName" errorName="maxlength">Limit is 50 characters.</div>
          <div appFormError="lastName" errorName="recurly">
            Last name {{ orderDetailsForm.get('lastName')?.errors?.recurly }}
          </div>
        </div>
      </app-input-wrapper>
    </div>

    <app-input-wrapper class="input-wrapper">
      <input
        appInputCtrl
        [appMarkTouchedIfLengthGreaterThan]="maxLengthList.address"
        formControlName="address1"
        class="app-input"
        type="text"
        name="street-address"
        [placeholder]="placeholders.address"
      />
      <div class="input-error">
        <div appFormError="address1" errorName="required">Please provide a valid address.</div>
        <div appFormError="address1" errorName="maxlength">Limit is 50 characters.</div>
        <div appFormError="address1" errorName="recurly">
          Address {{ orderDetailsForm.get('address1')?.errors?.recurly }}
        </div>
      </div>
    </app-input-wrapper>

    <app-input-wrapper class="input-wrapper">
      <input
        appInputCtrl
        [appMarkTouchedIfLengthGreaterThan]="maxLengthList.city"
        formControlName="city"
        [maxlength]="maxLengthList.city"
        class="app-input"
        type="text"
        name="home city"
        [placeholder]="placeholders.city"
      />
      <div class="input-error">
        <div appFormError="city" errorName="required">Please enter a city name.</div>
        <div appFormError="city" errorName="maxlength">Limit is 50 characters.</div>
        <div appFormError="city" errorName="recurly">City {{ orderDetailsForm.get('city')?.errors?.recurly }}</div>
      </div>
    </app-input-wrapper>

    <app-select
      class="app-select state-selector"
      formControlName="state"
      name="state"
      [cssClass]="orderDetailsForm.get('state')?.value ? '' : 'default'"
    >
      <option
        *ngFor="let state of states; let i = index"
        [value]="state.value"
        [disabled]="!state.value"
        [selected]="i == 0"
      >
        {{ state.name }}
      </option>
    </app-select>

    <div class="app-select-error" appFormError="state">
      <div appFormError="state" errorName="required">Please choose a state.</div>
      <div appFormError="state" errorName="recurly">State {{ orderDetailsForm.get('state')?.errors?.recurly }}</div>
    </div>
    <app-input-wrapper class="input-wrapper">
      <input
        appInputCtrl
        [appMarkTouchedIfLengthGreaterThan]="maxLengthList.postalCode"
        formControlName="postalCode"
        [maxlength]="maxLengthList.postalCode"
        class="app-input"
        inputmode="numeric"
        mask="00000"
        [validation]="false"
        type="text"
        name="postal-code"
        [placeholder]="placeholders.postalCode"
      />
      <div class="input-error">
        <div appFormError="postalCode" errorName="required">Please enter a ZIP code.</div>
        <div appFormError="postalCode" errorName="minlength">Please enter a valid ZIP code.</div>
        <div appFormError="postalCode" errorName="maxlength">Please enter a valid ZIP code.</div>
        <div appFormError="postalCode" errorName="recurly">
          Zip code {{ orderDetailsForm.get('postalCode')?.errors?.recurly }}
        </div>
      </div>
    </app-input-wrapper>

    <ng-container *ngIf="isShippingForm">
      <app-input-wrapper class="input-wrapper">
        <input
          #phoneInput
          appInputCtrl
          name="phone"
          class="app-input phone-input"
          mask="000-000-0000"
          inputmode="numeric"
          formControlName="phone"
          type="text"
          placeholder="Phone"
          (keydown.tab)="onHandlePhoneTabPressed($event)"
        />
        <span class="area-code-icon"></span>
        <div *ngIf="orderDetailsForm.get('phone')?.status !== 'PENDING'; else validatingPhoneTpl" class="input-error">
          <div appFormError="phone" errorName="required">Please enter a mobile number.</div>
          <div appFormError="phone" errorName="mask">Please enter a valid mobile number.</div>
          <div appFormError="phone" errorName="phoneAsync">
            {{ orderDetailsForm.get('phone')?.errors?.phoneAsync }}
          </div>
        </div>
        <ng-template #validatingPhoneTpl>
          <div class="input-validation-in-progress">
            Validating phone number...
          </div>
        </ng-template>
      </app-input-wrapper>

      <p class="all-required-tooltip">* All fields required.</p>
    </ng-container>
  </form>
</div>
