<select
  #inputRef
  class="select-input"
  [ngClass]="cssClass"
  (change)="onChangeHandler($event)"
  [disabled]="disabled || readonly"
  (blur)="onBlurHandler()"
  [name]="name"
>
  <ng-content></ng-content>
</select>
<cds-icon
  class="caret-icon"
  [class.disabled]="disabled || readonly"
  shape="angle"
  direction="down"
  size="16"
></cds-icon>
<div class="error-icon"></div>
