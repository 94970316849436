import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TSubscriptionPlan } from '../types/type';

import { AbstractEndpoints } from './abstract.endpoints';
import { IProductModel } from '../models/ibase-price.model';

const CALC_URL = '{apiUrl}/fleets/fulfillment/calculations/ordersubtotal';

interface ICalcReqDto {
  products: IProductModel[];
  currencyCode: 'USD'; // string
}

export interface ICalcResponseDto {
  amount: number;
  currencyCode: 'USD'; // string
}

@Injectable({ providedIn: 'root' })
export class CalculationsEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public calcSubtotal(reqParams: ICalcReqDto): Observable<ICalcResponseDto> {
    return this.httpClient.post<ICalcResponseDto>(this.createUrl(CALC_URL), reqParams);
  }
}
