<div class="info-container">
  <p class="hdc-desc">
    Big trucks are Class 6 and higher which need port-specific adapter cables to connect OBD devices.
  </p>
  <div class="row-wrapper">
    <div class="row-diagram-item trucks">
      <div class="diagram-item">
        <img src="/assets/images/truck-class-6.png" />
        <span>Class 6</span>
      </div>
      <div class="diagram-item">
        <img src="/assets/images/truck-class-7.png" />
        <span>Class 7</span>
      </div>
      <div class="diagram-item">
        <img src="/assets/images/truck-class-8.png" />
        <span>Class 8</span>
      </div>
    </div>
  </div>

  <div class="sub-title">Common port types:</div>
  <div class="row-wrapper">
    <div class="row-diagram-item ports">
      <div class="diagram-item">
        <img src="/assets/images/J-Bus-cable.png" />
        <span>J-BUS</span>
      </div>
      <div class="diagram-item">
        <img src="/assets/images/16-pin-cable.png" />
        <span>16-pin OBD</span>
      </div>
      <div class="diagram-item">
        <img src="/assets/images/RP-1226-cable.png" />
        <span>RP-1226</span>
      </div>
    </div>
  </div>
</div>
