import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecurlyControlComponent } from './components/recurly-control/recurly-control.component';
import { RecurlyFormComponent } from './components/recurly-form/recurly-form.component';
import { RecurlyControlValidatorDirective } from './components/recurly-control/recurly-control.validator';

@NgModule({
  declarations: [RecurlyControlComponent, RecurlyFormComponent, RecurlyControlValidatorDirective],
  exports: [RecurlyControlComponent, RecurlyFormComponent, RecurlyControlValidatorDirective],
  imports: [CommonModule],
})
export class RecurlyModule {}
