import { Component, ElementRef, ViewChild } from '@angular/core';
import { TokenPayload } from '@recurly/recurly-js';
import { RecurlyElementsService } from '../../services/recurly-elements.service';
import { RecurlyScriptLoader } from '../../services/recurly-script-loader';
import { IRecurlyError } from '../../types/recurly-error';

@Component({
  selector: 'app-recurly-form',
  template: '<form #formEl><ng-content></ng-content></form>',
  providers: [RecurlyElementsService],
})
export class RecurlyFormComponent {
  @ViewChild('formEl', { static: true })
  private formElRef!: ElementRef<HTMLFormElement>;

  constructor(private recurlyScriptLoader: RecurlyScriptLoader, private recurlyElements: RecurlyElementsService) {}

  public async getToken(): Promise<TokenPayload> {
    if (!this.formElRef) {
      throw new Error('HTMLFormElement not found');
    }
    const recurly = await this.recurlyScriptLoader.load();
    const elements = await this.recurlyElements.getElements();

    return new Promise<TokenPayload>((resolve, reject) => {
      recurly.token(elements, this.formElRef.nativeElement, (error: any, token: TokenPayload) =>
        error ? reject(error as IRecurlyError) : resolve(token),
      );
    });
  }
}
