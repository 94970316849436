import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-input-promo',
  templateUrl: './input-promo.component.html',
  styleUrls: ['./input-promo.component.scss'],
})
export class InputPromoComponent implements OnInit, OnDestroy {
  @Input()
  public invalidCode = false;

  @Output()
  public apply = new EventEmitter<string>();

  private readonly onDestroy$: Subject<void> = new Subject();

  promoForm = new FormGroup({
    promoCode: new FormControl('', Validators.required),
  });

  public ngOnInit(): void {
    this.promoForm.controls.promoCode.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.invalidCode) {
        this.invalidCode = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onSubmitForm(): void {
    this.apply.emit(this.promoForm.value.promoCode);
  }

  public reset(): void {
    this.promoForm.reset();
  }
}
