import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';
import { TSubscriptionPlan, TSubscriptionVendorPlan } from '../types/type';

const CODES_URL = '{apiUrl}/fleets/fulfillment/codes';

export interface ICodeResponseDto {
  couponCode: string;
  numberOfDevices: number;
  numberOfTrackers: number;
  numberOfDashcams: number;
  planCodeTrackersMonthly: TSubscriptionPlan;
  planCodeTrackersAnnual: TSubscriptionPlan;
  planCodeDashcamsMonthly: TSubscriptionPlan;
  planCodeDashcamsAnnual: TSubscriptionPlan;
  purchaseablePlans: IPurchaseablePlanDto[];
}

export interface IPurchaseablePlanDto {
  planCode: TSubscriptionPlan;
  flatShippingRate: number;
  freeShippingQuantityRequirement: number;
  costPerDevice: number;
  oneTimeDeviceFee: number;
  deviceHardwareCategory: string;
  subscriptionCycle: string;
}

export interface ICodeRequestDto {
  fulfillmentCode: string;
  clientId: string;
}

@Injectable({ providedIn: 'root' })
export class CodesEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public validateVendorCode(codeReqDto: ICodeRequestDto): Observable<ICodeResponseDto> {
    return this.httpClient.get<ICodeResponseDto>(this.createUrl(CODES_URL), {
      params: { ...codeReqDto },
    });
  }
}
