import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IRecurlyError } from '../types/recurly-error';
import { TRecurlyCardFields, TRecurlyFields } from '../types/types';

type TInvalidValue = {
  [value: string]: string;
};

const securedFields: TRecurlyCardFields[] = ['number', 'cvv', 'expiry', 'year'];

export class RecurlyValidatorsService {
  public securedErrors: { [proName in TRecurlyCardFields]?: string } = {};

  private invalidValueMap: {
    [fieldName: string]: TInvalidValue;
  } = {};

  private latestCommonErrorMsg: string | null = null;

  public commonErrorMsg(): string | null {
    return this.latestCommonErrorMsg;
  }
  public setError(fieldName: TRecurlyFields, value: string, errorMessage: string): void {
    if (securedFields.includes(fieldName as TRecurlyCardFields)) {
      this.securedErrors[fieldName as TRecurlyCardFields] = errorMessage;
      return;
    }

    if (!this.invalidValueMap[fieldName]) {
      this.invalidValueMap[fieldName] = {};
    }

    this.invalidValueMap[fieldName][value] = errorMessage;
  }

  public createValidator(fieldName: TRecurlyFields): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errorMessage = this.getError(fieldName, control.value);
      if (errorMessage) {
        return {
          recurly: errorMessage,
        };
      }
      return null;
    };
  }

  public handleError(
    recurlyError: IRecurlyError,
    values: { [fieldName in TRecurlyFields]?: string | undefined },
  ): boolean {
    this.latestCommonErrorMsg = recurlyError.message;

    if (!['validation', 'invalid-parameter'].includes(recurlyError.code)) {
      return false;
    }

    recurlyError.details.forEach(({ field, messages }) => {
      this.setError(field, values[field] || '', messages.join(', '));
    });

    return true;
  }

  public hasSecuredErrors(): boolean {
    return Object.values(this.securedErrors).length !== 0;
  }

  public resetSecuredErrors(): void {
    this.securedErrors = {};
  }

  private getError(fieldName: string, value: string): string | null {
    return (this.invalidValueMap[fieldName] && this.invalidValueMap[fieldName][value]) || null;
  }
}
