import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectDropdownComponent } from './select-dropdown.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { SelectDropdownOptionComponent } from './select-dropdown-option/select-dropdown-option.component';

@NgModule({
  declarations: [SelectDropdownComponent, SelectDropdownOptionComponent],
  exports: [SelectDropdownComponent, SelectDropdownOptionComponent],
  imports: [CommonModule, DropdownModule],
})
export class SelectDropdownModule {}
