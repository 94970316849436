import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const CONVERSION_TOKEN =
  'EAAr6o0IDzyEBAO0Hoq0kHjZAZCWmYU59268uSApwv3zJ9kiZCkAZC5Si5CRmPyQDssZBhqvGH0ZBoQ1xNiuUKsipdqFu8agFqzTOpq5OqXhpDKE832PPDJ4WCguWuef0ACosIbvzb2aLDlZAYJ4wyu9NHWFhxFASgRV57noKFuaWsbc1TfOrrUt';
const PIXEL_ID = '2636813983008446';
const API_VERSION = 'v16.0';

const CONVERSION_API_URL = `https://graph.facebook.com/${API_VERSION}/${PIXEL_ID}/events?access_token=${CONVERSION_TOKEN}`;

export interface IEventPayload {
  event_name: string;
  event_time: number;
  event_source_url: string;
  action_source: string;
  user_data: {
    em: string[]; // email SHA256 hashed
    ph: string[]; // phone number SHA256 hashed
  };
}

export interface IConversionsApiPost {
  data: IEventPayload[];
  test_event_code?: string; // only include code to create test event
}

export interface IConversionsApiRes {
  events_received: number;
  messages: string[];
  fbtrace_id: string;
}

@Injectable({ providedIn: 'root' })
export class MetaEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public conversionsApiEvent(payload: IConversionsApiPost): Observable<IConversionsApiRes> {
    return this.httpClient.post<IConversionsApiRes>(CONVERSION_API_URL, payload);
  }
}
