import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { VerificationLoader } from '../../loaders/verification.loader';

const DEBOUNCE_TIME = 1000;

@Injectable({ providedIn: 'root' })
export class AsyncValidatorsService {
  constructor(private loader: VerificationLoader) {}

  emailValidator(): (control: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      of(control.value).pipe(
        delay(DEBOUNCE_TIME), // debounce time
        switchMap((value) =>
          this.loader
            .verifyEmail(value)
            .pipe(map((errorMessage) => (errorMessage ? { emailAsync: errorMessage } : null))),
        ),
      );
  }

  phoneValidator(prefix: string): (control: AbstractControl) => Observable<ValidationErrors | null> {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      of(control.value).pipe(
        delay(DEBOUNCE_TIME), // debounce time
        switchMap((value) =>
          this.loader
            .verifyPhone(`${prefix}${value}`)
            .pipe(map((errorMessage) => (errorMessage ? { phoneAsync: errorMessage } : null))),
        ),
      );
  }
}
