import { Component, OnDestroy, OnInit } from '@angular/core';
import { distinctUntilChanged, map, take, takeUntil, throttleTime } from 'rxjs/operators';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { range } from 'src/app/utils/math';
import { IHeavyDutyCableInfo } from 'src/app/core/models/i-order-state.model';
import { HD_CABLE_INFO_MAP } from 'src/app/core/models/i-order-state.model';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { IDropdownItem } from 'src/app/shared/components/select-dropdown/select-dropdown.component';

@Component({
  selector: 'app-cable-cart',
  templateUrl: './cable-cart.component.html',
  styleUrls: ['./cable-cart.component.scss', '../order-payment.component.scss'],
})
export class CableCartComponent implements OnInit, OnDestroy {
  public addAnotherMakeTouched = new BehaviorSubject(false);
  public addAnotherMakeErrorMsg = false;
  public vehicleDropdownList: IDropdownItem<string>[] = [];

  private readonly onDestroy$: Subject<void> = new Subject<void>();

  constructor(public orderState: OrderStateService) {}

  public ngOnInit(): void {
    this.orderState
      .hdVehicleDropdown()
      .pipe(take(1))
      .subscribe((vehicleItems) => {
        this.vehicleDropdownList = vehicleItems;
      });

    combineLatest([this.orderState.onCartItemChange(), this.addAnotherMakeTouched])
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged(),
        throttleTime(500),
        map(() => {
          if (!this.orderState.cableLimitReached) {
            this.addAnotherMakeTouched.next(false);
          }
          // Error Message: To add another make, increase number in “I want to track X vehicles”.
          this.addAnotherMakeErrorMsg = this.addAnotherMakeTouched.value && this.orderState.cableLimitReached;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public getCableInfo(productCode: string): IHeavyDutyCableInfo {
    return HD_CABLE_INFO_MAP[productCode];
  }

  public getCableUnitPrice(vehicleMake: string): number {
    return this.orderState.getHeavyDutyCable(vehicleMake)?.oneTimeFee || 0;
  }

  public getCableUnitTotalPrice(vehicleMake: string, itemIndex: number): number {
    const quantity = this.orderState.cableCartItems?.[itemIndex].quantity;
    return this.getCableUnitPrice(vehicleMake) * quantity;
  }

  public quantityDropdown(quantity: number, maxInput: number = 1): number[] {
    return range(1, Math.max(quantity, maxInput));
  }

  public addAnotherMakeClicked(): void {
    this.addAnotherMakeTouched.next(true);
    if (!this.addAnotherMakeDisabled()) {
      this.orderState.addCableItem();
    }
  }

  public addAnotherMakeDisabled(): boolean {
    return this.orderState.cableLimitReached || this.orderState.lastCableItemNotSelected;
  }
}
