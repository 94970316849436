import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CalculationsEndpoints } from '../endpoints/calculations.endpoints';
import {
  CouponsEndpoints,
  IFixedDiscountCouponDto,
  IFreeTrialCouponDto,
  IPercentageBasedCouponDto,
  TCouponResponseDto,
} from '../endpoints/coupons.endpoint';
import { FulfillmentEndpoints, IFulfillmentPlanDto, IFulfillmentResponseDto } from '../endpoints/fulfillment.endpoints';
import { IHeavyDutyCablesByMake, TBillingPeriod } from '../models/i-order-state.model';
import { IPlanPriceModel, IProductModel, TPlanTypeModel } from '../models/ibase-price.model';
import { IPromoCodeInvalid, TPromoCodeModel } from '../models/ipromo-code.model';
import { TSubscriptionPlan } from '../types/type';

@Injectable({ providedIn: 'root' })
export class PriceLoader {
  constructor(
    private fulfillmentEndpoints: FulfillmentEndpoints, //
    private calculationsEndpoints: CalculationsEndpoints,
    private couponsEndpoints: CouponsEndpoints,
  ) {}

  public loadSubsPlanPriceMap(): Observable<[TPlanTypeModel, IHeavyDutyCablesByMake]> {
    return this.fulfillmentEndpoints.getFulfillment().pipe(map((dto) => mapBasePriceModel(dto)));
  }

  public loadSubTotalPrice(products: IProductModel[]): Observable<number> {
    if (!products.length) {
      return of(0);
    }

    return this.calculationsEndpoints
      .calcSubtotal({
        products,
        currencyCode: 'USD',
      })
      .pipe(map((dto) => dto.amount));
  }

  /**
   * loadPromoCode
   * billingPeriod: TBillingPeriod
   * promoCode: string
   */
  public loadPromoCode(
    billingPeriod: TBillingPeriod,
    couponCode: string,
    planCode: TSubscriptionPlan,
  ): Observable<TPromoCodeModel> {
    return this.couponsEndpoints
      .getCoupon({
        couponCode,
        planCode,
        clientId: this.couponsEndpoints.getClientId(),
      })
      .pipe(
        map((dto) => mapPromoCode(dto, billingPeriod)),
        catchError(() => {
          // return of<any>({
          //   couponCode,
          //   type: 'fixed',
          //   fixedAmount: 100
          // });

          return of<IPromoCodeInvalid>({
            billingPeriod,
            couponCode,
            type: 'invalid',
          });
        }),
      );
  }
}

function mapBasePriceModel(dto: IFulfillmentResponseDto): [TPlanTypeModel, IHeavyDutyCablesByMake] {
  const { plansByCode, heavyDutyCablesByMake } = dto;

  return [
    {
      trakViewPro: {
        monthly: mapSubsPlan(plansByCode.dashcam_premium_monthly_01),
        annual: mapSubsPlan(plansByCode.dashcam_premium_annual_01),
      },
      gpsPremium: {
        monthly: mapSubsPlan(plansByCode.tracker_premium_monthly_01),
        annual: mapSubsPlan(plansByCode.tracker_premium_annual_01),
      },
      gpsStarter: {
        monthly: mapSubsPlan(plansByCode.tracker_starter_monthly_01),
        annual: mapSubsPlan(plansByCode.tracker_starter_annual_01),
      },
    },
    heavyDutyCablesByMake || {},
  ];

  function mapSubsPlan(plan: IFulfillmentPlanDto): IPlanPriceModel {
    return {
      shippingRate: plan.flatShippingRate,
      freeShippingQty: plan.freeShippingQuantityRequirement,
      costPerDevice: plan.costPerDevice,
      oneTimeDeviceFee: plan.oneTimeDeviceFee,
      planCode: plan.planCode as TSubscriptionPlan,
    };
  }
}

function mapPromoCode(dto: TCouponResponseDto, billingPeriod: TBillingPeriod): TPromoCodeModel {
  if ((dto as IPercentageBasedCouponDto).discountPercentage) {
    return {
      billingPeriod,
      couponCode: dto.couponCode,
      type: 'percentage',
      discountPercentage: (dto as IPercentageBasedCouponDto).discountPercentage,
    };
  }

  if ((dto as IFixedDiscountCouponDto).discountFixedAmounts) {
    const fixedPromo = dto as IFixedDiscountCouponDto;
    return {
      billingPeriod,
      couponCode: dto.couponCode,
      type: 'fixed',
      fixedAmount: (fixedPromo.discountFixedAmounts.length && fixedPromo.discountFixedAmounts[0].value) || 0,
    };
  }
  if ((dto as IFreeTrialCouponDto).freeTrialLength) {
    return {
      billingPeriod,
      couponCode: dto.couponCode,
      type: 'free',
      trialLength: (dto as IFreeTrialCouponDto).freeTrialLength,
      trialUnit: (dto as IFreeTrialCouponDto).freeTrialUnit,
    };
  }

  return {
    billingPeriod,
    couponCode: dto.couponCode,
    type: 'invalid',
  };
}
