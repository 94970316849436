<div *ngIf="hintType !== 'modal'" class="hint-arrow"></div>

<div *ngIf="data?.contentType === 'template'; else htmlOrStringTemplate">
  <ng-container *ngTemplateOutlet="data?.content"></ng-container>
</div>

<ng-template #htmlOrStringTemplate>
  <div [innerHTML]="data?.content"></div>
</ng-template>

<p *ngIf="hintType === 'modal'" class="close-btn" (click)="onCloseModal()">Close</p>
