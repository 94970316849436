export function debounce(delay: number = 300): MethodDecorator {
  return (target: object, propertyKey: string | symbol, descriptor: PropertyDescriptor): PropertyDescriptor => {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function(...args: any): void {
      clearTimeout((this as any)[timeoutKey]);
      (this as any)[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
