import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkTouchedIfLengthGreaterThanDirective } from './mark-touched-if-length-greater-than.directive';

@NgModule({
  declarations: [MarkTouchedIfLengthGreaterThanDirective],
  imports: [CommonModule],
  exports: [MarkTouchedIfLengthGreaterThanDirective],
})
export class MarkTouchedIfLengthGreaterThanModule {}
