import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { IValidationPostDto, VerificationEndpoints } from '../endpoints/verification.endpoints';

@Injectable({ providedIn: 'root' })
export class VerificationLoader {
  constructor(private endpoints: VerificationEndpoints) {}

  public verifyPhone(phone: string): Observable<null | string> {
    return this.makeRequest({ phone }, 'Please enter a valid phone number.');
  }

  public verifyEmail(email: string): Observable<null | string> {
    return this.makeRequest({ email }, 'Email not available.');
  }

  private makeRequest(
    params: { email?: string; phone?: string },
    alternateErrorMessage: string,
  ): Observable<null | string> {
    const req: IValidationPostDto = {
      ClientId: this.endpoints.getClientId(),
    };
    if (params.email) {
      req.Email = params.email;
    }

    if (params.phone) {
      req.PhoneNumber = params.phone;
    }

    return this.endpoints.verify(req).pipe(
      mapTo(null),
      catchError((e) => of((e.error && e.error.Message) || alternateErrorMessage)),
    );
  }
}
