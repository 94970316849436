import { Component, Input } from '@angular/core';

export type TStepsBarStep = 'order' | 'shipping' | 'payment';

@Component({
  selector: 'app-steps-bar',
  templateUrl: './steps-bar.component.html',
  styleUrls: ['./steps-bar.component.scss'],
})
export class StepsBarComponent {
  @Input()
  public activeStep!: TStepsBarStep;
}
