<div class="steps-bar">
  <div class="point" [class.active]="'order' === activeStep">
    <cds-icon shape="document" size="30"></cds-icon>
    <div class="point-title">Order Details</div>
  </div>

  <div class="line"></div>

  <div class="point" [class.active]="'shipping' === activeStep">
    <cds-icon shape="bundle" size="30"></cds-icon>
    <div class="point-title">Shipping Details</div>
  </div>

  <div class="line"></div>

  <div class="point" [class.active]="'payment' === activeStep">
    <cds-icon shape="credit-card" size="30"></cds-icon>
    <div class="point-title">Payment Details</div>
  </div>
</div>
