import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-background-layer',
  templateUrl: './background-layer.component.html',
  styleUrls: ['./background-layer.component.scss'],
})
export class BackgroundLayerComponent {
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.preventDefault();
  }
}
