<div class="summary-line-item">
  <span>Fleet Size ({{ planDeviceName }}):</span>
  <span>{{ devicesCount }}</span>
</div>
<div *ngIf="!isVendorSpecific" class="summary-line-item">
  <span>Device Cost:</span>
  <span>{{ totalDevicesPrice | price }}</span>
</div>
<div class="summary-line-item">
  <span>Billing Cycle:</span>
  <span>{{ 'Monthly' | annual: 'Annual' }}</span>
</div>
<div class="summary-line-item">
  <span>{{ 'Monthly Subscription' | annual: 'Annual Subscription' }}:</span>
  <span>{{ totalSubscriptionPlanPrice | price }}</span>
</div>
