import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICaptchaInformationReq,
  IRegistrationAddressDto,
  IRegistrationPostReqDto,
  IRegistrationPostResDto,
  RegistrationEndpoints,
} from '../endpoints/registrations.endpoints';
import { IAddressDetails } from '../models/i-order-state.model';
import { IProductModel } from '../models/ibase-price.model';
import { TSubscriptionPlan } from '../types/type';

@Injectable({ providedIn: 'root' })
export class RegistrationLoader {
  constructor(private endpoints: RegistrationEndpoints) {}

  public register(
    recurlyToken: string,
    promoCode: string,
    email: string,
    phone: string,
    howDidYouFindUs: string,
    company: string,
    devicesCount: number,
    shippingAddress: IAddressDetails,
    paymentAddress: IAddressDetails,
    vendorFulfillmentCode: string = '',
    products: IProductModel[],
    planCode: TSubscriptionPlan,
    captchaInfo: ICaptchaInformationReq,
  ): Observable<IRegistrationPostResDto> {
    const body: IRegistrationPostReqDto = {
      ClientId: this.endpoints.getClientId(),
      TokenId: recurlyToken,
      FirstName: shippingAddress.firstName,
      LastName: shippingAddress.lastName,
      Email: email,
      PhoneNumber: phone,
      CompanyName: company,
      PlanCode: planCode,
      CouponCode: promoCode,
      BillingAddress: addressDetailsToDto(phone, paymentAddress),
      ShippingAddress: addressDetailsToDto(phone, shippingAddress),
      DeviceCount: devicesCount,
      LeadSource: howDidYouFindUs,
      Products: products,
      CaptchaInformation: captchaInfo,
    };
    if (vendorFulfillmentCode) {
      body.VendorFulfillmentCode = vendorFulfillmentCode;
    }
    return this.endpoints.registration(body);
  }
}

function addressDetailsToDto(phone: string, address: IAddressDetails): IRegistrationAddressDto {
  return {
    FirstName: address.firstName,
    LastName: address.lastName,
    Phone: phone,
    Company: address.companyName,
    Street1: address.address1,
    Street2: address.address2,
    City: address.city,
    Region: address.state,
    PostalCode: address.postalCode,
    Country: address.country,
  };
}
