import { Component, Input } from '@angular/core';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { TStepsBarStep } from './components/steps-bar/steps-bar.component';

@Component({
  selector: 'app-order-layout',
  templateUrl: './order-layout.component.html',
  styleUrls: ['./order-layout.component.scss'],
})
export class OrderLayoutComponent {
  @Input()
  public activeStep: TStepsBarStep = 'order';

  @Input()
  public isOrderDetailsCollapsed = false;

  @Input()
  public isVendorSpecific = false;

  constructor(public orderState: OrderStateService) {}
}
