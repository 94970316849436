import { Injectable } from '@angular/core';
import { ElementsInstance } from '@recurly/recurly-js';
import { RecurlyScriptLoader } from './recurly-script-loader';

@Injectable()
export class RecurlyElementsService {
  private elementsPromise!: Promise<ElementsInstance>;
  constructor(private recurlyScriptLoader: RecurlyScriptLoader) {}

  public async getElements(): Promise<ElementsInstance> {
    if (!this.elementsPromise) {
      this.elementsPromise = new Promise(async (resolve) => {
        const recurly = await this.recurlyScriptLoader.load();
        resolve(recurly.Elements());
      });
    }
    return this.elementsPromise;
  }
}
