import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { IAppendedComponentData } from '../../models/iappended-component-data';
import { THintType, TPosition } from '../../models/hint-types';
import { WINDOW } from '../../services/window.service';
import { IHintEvent } from '../../models/ihint-event';

interface ICalculatedPositionInfo {
  left: number;
  right: number;
  top: number;
  bottom: number;
  position?: TPosition;
}

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: { class: 'hint' },
})
export class HintComponent implements OnInit, OnDestroy {
  @Input()
  public data: IAppendedComponentData | undefined;

  @Input()
  set show(value: boolean) {
    if (value) {
      this.setPosition();
    } else {
      this.hostStyleLeft = '-10000px';
      this.hostStyleTop = '-10000px';
    }
    this.shown = this.hostClassShow = value;
  }
  get show(): boolean {
    return this.shown;
  }

  @Output()
  public events: EventEmitter<IHintEvent> = new EventEmitter<IHintEvent>();

  @Output()
  public onclose: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('style.left') hostStyleLeft = '-10000px';
  @HostBinding('style.top') hostStyleTop = '-10000px';
  @HostBinding('style.width') hostStyleWidth: string | undefined;
  @HostBinding('style.min-width') hostStyleMinWidth: string | undefined;
  @HostBinding('style.max-width') hostStyleMaxWidth: string | undefined;
  @HostBinding('class.hint-show') hostClassShow: boolean | undefined;
  @HostBinding('class.tooltip') hostClassTooltipType = false;
  @HostBinding('class.modal') hostClassModalType = false;

  get element(): HTMLElement | undefined {
    return this.data?.element;
  }

  get elementPosition(): DOMRect | undefined {
    return this.data?.elementPosition;
  }

  get contentPosition(): TPosition {
    return this.lastAddedPosition || this.data?.contentPosition || 'top-left';
  }

  get tipOffset(): number {
    return this.data?.tipOffset || 0;
  }

  get shiftX(): number {
    return this.data?.shiftX || 0;
  }

  get shiftY(): number {
    return this.data?.shiftY || 0;
  }

  get hintType(): THintType | undefined {
    return this.data?.hintType;
  }

  get showDelay(): number {
    return this.data?.showDelay || 300;
  }

  get isAutoPositioning(): boolean {
    return this.data?.autoPositioning || true;
  }

  get isAddedAfterParent(): boolean {
    return this.data?.isAddedAfterParent || false;
  }

  get isClosingOnLeave(): boolean {
    return this.data?.closeOnLeave || false;
  }

  private shown = false;
  private lastAddedPosition: TPosition | undefined = undefined;

  private readonly onDestroy$: Subject<void> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('focusin')
  @HostListener('mouseenter')
  public onMouseEnterHandler(): void {
    if (this.isClosingOnLeave) {
      this.events.emit({ type: 'entered' });
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeaveHandler(): void {
    if (this.isClosingOnLeave) {
      this.onclose.emit();
    }
  }

  public ngOnInit(): void {
    this.setContentClasses();
    this.setContentStyles();
    this.resetContentPosition();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public setContentClasses(): void {
    if (this.data?.contentClass) {
      this.data.contentClass.split(' ').forEach((className) => {
        this.renderer.addClass(this.elementRef.nativeElement, className);
      });
    }

    if (this.hintType === 'tooltip') {
      this.hostClassTooltipType = true;
    }

    if (this.hintType === 'modal') {
      this.hostClassModalType = true;
    }
  }

  public setContentStyles(): void {
    this.hostStyleWidth = this.data?.width ? `${this.data.width}px` : '';
    if ((this.data?.width || 0) > (this.data?.maxWidth || 0)) {
      this.hostStyleMaxWidth = '';
    } else {
      this.hostStyleMaxWidth = this.data?.maxWidth ? `${this.data.maxWidth}px` : '';
    }
  }

  public setPositionClass(position: TPosition | undefined): void {
    if (!!this.lastAddedPosition) {
      this.renderer.removeClass(this.elementRef.nativeElement, `hint-${this.lastAddedPosition}`);
    }
    this.lastAddedPosition = position;
    this.renderer.addClass(this.elementRef.nativeElement, `hint-${position}`);
  }

  public setPosition(): void {
    let positionInfo = this.calculatePosition(this.contentPosition);

    if (this.isAutoPositioning && !this.isFitToWindow(positionInfo)) {
      const altPositionInfo = this.findPosition();
      if (!!altPositionInfo) {
        positionInfo = altPositionInfo;
      }
    }
    const left = Math.max(0, positionInfo.left);
    this.setPositionClass(positionInfo.position);
    this.hostStyleMinWidth = `${positionInfo.right - left}px`;
    this.hostStyleTop = `${positionInfo.top}px`;
    this.hostStyleLeft = `${left}px`;
  }

  public resetContentPosition(): void {
    this.show = false;
    timer(this.showDelay)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.show = true;
        this.events.emit({ type: 'shown' });
      });
  }

  public setTopLeftCoords(): void {
    const positionInfo = this.calculatePosition(this.contentPosition);
    this.hostStyleTop = `${positionInfo.top}px`;
    this.hostStyleLeft = `${positionInfo.left}px`;
  }

  public onCloseModal(): void {
    this.onclose.next();
  }

  private calculatePosition(position: TPosition | undefined): ICalculatedPositionInfo {
    const hint = this.elementRef.nativeElement;
    const hintHeight = hint.clientHeight;
    const hintWidth = hint.clientWidth;
    const scrollY =
      this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    const scrollX =
      this.window.pageXOffset || this.document.documentElement.scrollLeft || this.document.body.scrollLeft || 0;

    let top: number;
    let left: number;

    if (this.hintType === 'modal') {
      top = (window.innerHeight - hintHeight) / 2 + scrollY;
      left = (window.innerWidth - hintWidth) / 2 + scrollX;
    } else {
      const isSvg = this.element instanceof SVGElement;
      const elementPosition: DOMRect | ClientRect | undefined = this.element?.getBoundingClientRect();
      const elementHeight = (isSvg ? elementPosition?.height : this.element?.offsetHeight) || 0;
      const elementWidth = (isSvg ? elementPosition?.width : this.element?.offsetWidth) || 0;

      const elementTop = this.isAddedAfterParent ? 0 : (elementPosition?.top || 0) + scrollY;
      const elementLeft = this.isAddedAfterParent ? 0 : (elementPosition?.left || 0) + scrollX;

      const offsetY = 14;
      const offsetX = 16;

      top = this.shiftY;
      left = this.shiftX;

      switch (position) {
        case 'top-left':
          top += elementTop - hintHeight - this.tipOffset;
          left += elementLeft + elementWidth / 2 - hintWidth + offsetX;
          break;
        case 'top-middle':
          top += elementTop - hintHeight - this.tipOffset;
          left += elementLeft + elementWidth / 2 - hintWidth / 2;
          break;
        case 'top-right':
          top += elementTop - hintHeight - this.tipOffset;
          left += elementLeft + elementWidth / 2 - offsetX;
          break;
        case 'right-bottom':
          top += elementTop + elementHeight / 2 - offsetY;
          left += elementLeft + elementWidth + this.tipOffset;
          break;
        case 'right-middle':
          top += elementTop + elementHeight / 2 - hintHeight / 2;
          left += elementLeft + elementWidth + this.tipOffset;
          break;
        case 'right-top':
          top += elementTop + elementHeight / 2 - hintHeight + offsetY;
          left += elementLeft + elementWidth + this.tipOffset;
          break;
        case 'bottom-left':
          top += elementTop + elementHeight + this.tipOffset;
          left += elementLeft + elementWidth / 2 - hintWidth + offsetX;
          break;
        case 'bottom-middle':
          top += elementTop + elementHeight + this.tipOffset;
          left += elementLeft + elementWidth / 2 - hintWidth / 2;
          break;
        case 'bottom-right':
          top += elementTop + elementHeight + this.tipOffset;
          left += elementLeft + elementWidth / 2 - offsetX;
          break;
        case 'left-bottom':
          top += elementTop + elementHeight / 2 - offsetY;
          left += elementLeft - hintWidth - this.tipOffset;
          break;
        case 'left-middle':
          top += elementTop + elementHeight / 2 - hintHeight / 2;
          left += elementLeft - hintWidth - this.tipOffset;
          break;
        case 'left-top':
          top += elementTop + elementHeight / 2 - hintHeight + offsetY;
          left += elementLeft - hintWidth - this.tipOffset;
          break;
        default:
          top += 0;
          left += 0;
      }
    }

    return {
      position,
      top,
      left,
      right: left + hintWidth,
      bottom: top + hintHeight,
    };
  }

  private isFitToWindow(positionInfo: ICalculatedPositionInfo): boolean {
    const elementPosition: DOMRect | ClientRect | undefined = this.element?.getBoundingClientRect();

    const scrollY =
      this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    const scrollX =
      this.window.pageXOffset || this.document.documentElement.scrollLeft || this.document.body.scrollLeft || 0;

    const offsetY = this.isAddedAfterParent ? (elementPosition?.top || 0) + scrollY : 0;
    const offsetX = this.isAddedAfterParent ? (elementPosition?.left || 0) + scrollX : 0;

    return (
      positionInfo.left + offsetX - scrollX >= 0 &&
      positionInfo.right + offsetX - scrollX <= this.window.innerWidth &&
      positionInfo.top + offsetY - scrollY >= 0 &&
      positionInfo.bottom + offsetY - scrollY <= this.window.innerHeight
    );
  }

  private findPosition(): ICalculatedPositionInfo | null {
    const positions: TPosition[] = [
      'top-left',
      'top-middle',
      'top-right',
      'bottom-left',
      'bottom-middle',
      'bottom-right',
      'left-top',
      'left-middle',
      'left-bottom',
      'right-top',
      'right-middle',
      'right-bottom',
    ];

    for (const position of positions) {
      const positionInfo = this.calculatePosition(position);
      if (this.isFitToWindow(positionInfo)) {
        return positionInfo;
      }
    }

    return null;
  }
}
