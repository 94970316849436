import '@cds/core/icon/register.js';
import {
  ClarityIcons,
  bundleIcon,
  angleIcon,
  infoCircleIcon,
  carIcon,
  thumbsUpIcon,
  envelopeIcon,
  windowCloseIcon,
  minusCircleIcon,
  plusCircleIcon,
  trashIcon,
} from '@cds/core/icon';
import { creditCardIcon, documentIcon } from './custom-icons';

ClarityIcons.addIcons(
  bundleIcon,
  angleIcon,
  infoCircleIcon,
  carIcon,
  thumbsUpIcon,
  envelopeIcon,
  windowCloseIcon,
  minusCircleIcon,
  plusCircleIcon,
  trashIcon,
);

ClarityIcons.addIcons(['credit-card', creditCardIcon]);
ClarityIcons.addIcons(['document', documentIcon]);
