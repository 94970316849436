import { Directive, Input, OnDestroy } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { RecurlyValidatorsService } from '../../services/recurly-validators.service';
import { RecurlyControlComponent } from './recurly-control.component';

@Directive({
  selector: '[appRecurlyControlValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: RecurlyControlValidatorDirective, multi: true }],
})
export class RecurlyControlValidatorDirective implements AsyncValidator, OnDestroy {
  @Input()
  public appRecurlyControlValidator = 'recurly';

  private isDestroyed = false;

  constructor(private recurlyControl: RecurlyControlComponent, private validatorService: RecurlyValidatorsService) {}

  public validate(): Observable<ValidationErrors | null> {
    if (this.isDestroyed) {
      return of(null);
    }
    return of(
      this.recurlyControl?.valid && !this.validatorService.hasSecuredErrors()
        ? null
        : {
            [this.appRecurlyControlValidator || 'recurly']: {
              ...this.recurlyControl.state,
              ...(this.validatorService.hasSecuredErrors() ? { remote: this.validatorService.securedErrors } : null),
            },
          },
    );
  }
  public ngOnDestroy(): void {
    this.isDestroyed = true;
  }
}
