import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrderStateService } from 'src/app/core/service/order-state.service';

@Pipe({
  name: 'annual',
  pure: false,
})
export class AnnualPipe implements PipeTransform, OnDestroy {
  private subs!: Subscription;
  constructor(
    private orderState: OrderStateService, //
    private chRef: ChangeDetectorRef,
  ) {
    this.subs = this.orderState.billingPeriodChanges().subscribe(() => this.chRef.markForCheck());
  }

  public ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public transform(monthlyVal: any, annualVal: any): any {
    return this.orderState.billingPeriod() === 'annual' ? annualVal : monthlyVal;
  }
}
