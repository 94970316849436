<div *ngIf="open">
  <div class="modal-overlay">
    <div class="modal">
      <div *ngIf="isCloseable" (click)="closeModal()" class="close-btn">
        <cds-icon shape="window-close"></cds-icon>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
