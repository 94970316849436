<div class="shipping-details">
  <div *ngIf="planType !== 'hybrid'" class="device-preview md-hide">
    <img [src]="planDevicePreviewImg" alt="device" />
  </div>

  <app-address-form *ngIf="!isReview; else review" [isShippingForm]="true"></app-address-form>

  <ng-template #review>
    <div class="container">
      <h3 class="section-header">Shipping and Payment</h3>
      <div class="details-item">
        <div class="details-item-label">Contact:</div>
        <div class="details-item-content">
          {{ shippingDetails?.companyName }}<br />
          {{ shippingDetails?.email }}<br />
          {{ shippingDetails?.phone | phone }}
        </div>
      </div>

      <div class="separator"></div>

      <div class="details-item">
        <div class="details-item-label">Payment Method:</div>
        <div class="details-item-content">{{ paymentDetails }}</div>
      </div>

      <div class="separator"></div>

      <div class="details-item">
        <div class="details-item-label">Shipping Address:</div>
        <div class="details-item-content">
          {{ shippingDetails?.firstName }} {{ shippingDetails?.lastName }}<br />
          {{ shippingDetails?.address1 }}<br />
          {{ shippingDetails?.city }}, {{ shippingDetails?.state | stateName }},<br />
          {{ shippingDetails?.postalCode }}
        </div>
      </div>

      <div class="separator"></div>

      <div class="details-item">
        <div class="details-item-label">Billing Address:</div>
        <div class="details-item-content">
          {{ billingDetails?.firstName }} {{ billingDetails?.lastName }}<br />
          {{ billingDetails?.address1 }}<br />
          {{ billingDetails?.city }}, {{ billingDetails?.state | stateName }},<br />
          {{ billingDetails?.postalCode }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
