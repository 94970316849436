import { Component } from '@angular/core';
import { OrderStateService } from 'src/app/core/service/order-state.service';

@Component({
  selector: 'app-faq-tooltip',
  templateUrl: './faq-tooltip.component.html',
  styleUrls: ['./faq-tooltip.component.scss'],
})
export class FaqTooltipComponent {
  constructor(public orderState: OrderStateService) {}
}
