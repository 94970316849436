import { Component, HostBinding, Input } from '@angular/core';
import { InputService } from '../input.service';

@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
  providers: [InputService],
})
export class InputWrapperComponent {
  @Input()
  public onErrorCls!: string;

  constructor(private onErrorService: InputService) {}

  @HostBinding('class.has-error') get class(): boolean {
    return this.onErrorService.hasError;
  }

  @HostBinding('class.is-focused') get isFocused(): boolean {
    return this.onErrorService.isFocused;
  }

  @HostBinding('class.is-disabled') get isDisabled(): boolean {
    return this.onErrorService.isDisabled;
  }
}
