<button
  class="dropdown-btn"
  [class.is-open]="isOpen"
  (click)="isOpen = !isOpen"
  [class.has-error]="hasError"
  (blur)="onBlur()"
>
  <div>
    <ng-content select="app-dropdown-title"></ng-content>
  </div>
  <cds-icon class="caret-icon" shape="angle" [attr.direction]="isOpen ? 'up' : 'down'" size="16"></cds-icon>
  <span class="error-icon"></span>
</button>
<div *ngIf="isOpen" class="dropdown-menu" (click)="isOpen = false">
  <ng-content></ng-content>
</div>
