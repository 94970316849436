import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const VERIFY_URL = '{apiUrl}/fleets/fulfillment/verifications';

export interface IValidationPostDto {
  PhoneNumber?: string;
  Email?: string;
  ClientId: string;
}

@Injectable({ providedIn: 'root' })
export class VerificationEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public verify(postData: IValidationPostDto): Observable<void> {
    return this.httpClient.post<void>(this.createUrl(VERIFY_URL), postData);
  }
}
