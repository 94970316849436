import { ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  public isOpen = false;

  @Input()
  public hasError = false;

  @Output()
  public blurDropdown = new EventEmitter<void>();

  constructor(private elRef: ElementRef) {}

  public onBlur(): void {
    this.blurDropdown.emit();
  }

  @HostListener('window:click', ['$event'])
  public onWindowClick(event: any): void {
    if (this.isOpen) {
      let el: HTMLElement | null = event.target;
      while (el && el !== window.document.body) {
        if (el === this.elRef.nativeElement) {
          return;
        }
        el = el.parentElement || null;
      }

      this.isOpen = false;
    }
  }
}
