import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

// export interface ISelectDDOption {
//   value: any;
//   title: string;
// }

@Component({
  selector: 'app-select-dropdown-option',
  templateUrl: './select-dropdown-option.component.html',
  styleUrls: ['./select-dropdown-option.component.scss'],
})
export class SelectDropdownOptionComponent implements OnInit {
  @ViewChild('btn', { static: true })
  public btnRef!: ElementRef<HTMLButtonElement>;

  @Input()
  public value: any;

  @Output()
  public onselect = new EventEmitter<SelectDropdownOptionComponent>();

  ngOnInit(): void {}

  @HostListener('click')
  public onClick(): void {
    this.onselect.emit(this);
  }

  public getTitle(): string {
    return this.btnRef.nativeElement.textContent || '';
  }
}
