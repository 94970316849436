import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, pairwise, takeUntil } from 'rxjs/operators';
import { RecurlyValidatorsService } from 'src/app/shared/components/recurly/services/recurly-validators.service';
import { RequiredAfterTrimmingValidator } from '../../../../core/forms/validators/required-after-trimming.validator';
import { EmailValidator } from '../../../../core/forms/validators/email.validator';
import { STATES } from '../../../../shared/constants/states';
import { AsyncValidatorsService } from '../../../../core/forms/async-validators/async-validators.service';
import { OrderStateService } from '../../../../core/service/order-state.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @Input()
  public isShippingForm = false;
  public states = STATES;
  public placeholders = {
    companyName: 'Company name',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    address: 'Address',
    city: 'City',
    state: 'State',
    postalCode: 'ZIP code',
    mobileNumber: 'Mobile number',
  };
  public maxLengthList = {
    companyName: 100,
    firstName: 50,
    lastName: 50,
    address: 50,
    city: 50,
    postalCode: 5,
  };

  public orderDetailsForm!: FormGroup;
  public emailTabSubj$: Subject<boolean> = new Subject<boolean>();
  public phoneTabSubj$: Subject<boolean> = new Subject<boolean>();
  private destroy$ = new Subject<void>();

  @ViewChild('companyName', { static: true })
  public companyNameElRef!: ElementRef<HTMLInputElement>;
  @ViewChild('firstNameInput', { static: false })
  private firstNameInputElRef!: ElementRef<HTMLInputElement>;

  constructor(
    @Optional()
    private recurlyValidators: RecurlyValidatorsService,
    private asyncValidators: AsyncValidatorsService,
    private orderStateService: OrderStateService,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    const dummy = () => null;
    this.orderDetailsForm = new FormGroup({
      companyName: new FormControl(
        '',
        this.isShippingForm
          ? [RequiredAfterTrimmingValidator, Validators.maxLength(this.maxLengthList.companyName)]
          : [],
      ),
      email: new FormControl(
        '',
        this.isShippingForm
          ? {
              validators: [Validators.required, EmailValidator],
              asyncValidators: this.asyncValidators.emailValidator(),
            }
          : {},
      ),
      firstName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.firstName),
        this.recurlyValidators?.createValidator('first_name') || dummy,
      ]),
      lastName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.lastName),
        this.recurlyValidators?.createValidator('last_name') || dummy,
      ]),
      address1: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.address),
        this.recurlyValidators?.createValidator('address1') || dummy,
      ]),
      address2: new FormControl(''),
      city: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.city),
        this.recurlyValidators?.createValidator('city') || dummy,
      ]),
      state: new FormControl('', [Validators.required, this.recurlyValidators?.createValidator('state') || dummy]),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.maxLengthList.postalCode),
        Validators.minLength(5),
        this.recurlyValidators?.createValidator('postal_code') || dummy,
      ]),
      phone: new FormControl(
        '',
        this.isShippingForm
          ? {
              validators: [this.isShippingForm ? Validators.required : dummy, Validators.minLength(9)],
              asyncValidators: this.asyncValidators.phoneValidator('1'),
            }
          : {},
      ),
      country: new FormControl('US'),
    });

    const orderState = this.orderStateService.stateValue();
    if (this.isShippingForm && orderState.shippingAddress && orderState.isValidShippingDetails) {
      this.orderDetailsForm.setValue(orderState.shippingAddress);
    }
    if (!this.isShippingForm && orderState.billingAddress && orderState.isValidBillingAddress) {
      this.orderDetailsForm.setValue(orderState.billingAddress);
    }

    this.orderDetailsForm.statusChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((status) => {
      this.orderStateService.updateState({
        isStatusPending: status === 'PENDING',
      });
      if (status !== 'PENDING') {
        this.isShippingForm
          ? this.orderStateService.setShippingAddress(this.orderDetailsForm.value, this.orderDetailsForm.valid)
          : this.orderStateService.setBillingAddress(this.orderDetailsForm.value, this.orderDetailsForm.valid);
      }
    });

    if (this.isShippingForm) {
      this.setEmailFromParam();

      combineLatest([
        this.emailTabSubj$.pipe(distinctUntilChanged()),
        this.orderDetailsForm.controls.email.statusChanges.pipe(distinctUntilChanged()),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([isPressed, status]) => {
          if (isPressed) {
            // return if control status is still pending
            if (status === 'PENDING') {
              return;
            }
            // move focus otherwise
            if (status === 'INVALID' || status === 'VALID') {
              this.emailTabSubj$.next(false);
              this.firstNameInputElRef.nativeElement.focus();
            }
          }
        });

      combineLatest([
        this.phoneTabSubj$.pipe(distinctUntilChanged()),
        this.orderDetailsForm.controls.phone.statusChanges.pipe(pairwise()),
      ])
        .pipe(takeUntil(this.destroy$))
        .subscribe(([isPressed, [prev, curr]]) => {
          if (isPressed) {
            // return if control status is still pending
            if (curr === 'PENDING') {
              return;
            }
            // control status is invalid
            if (prev === 'PENDING' && curr === 'INVALID') {
              this.phoneTabSubj$.next(false);
              this.orderDetailsForm.controls.phone.markAsTouched();
              return;
            }
          }
        });
    }
  }

  public onHandleEmailTabPressed(event: Event): void {
    if (event.isTrusted && this.orderDetailsForm.controls.email.status === 'PENDING') {
      event.stopPropagation();
      event.preventDefault();
      this.emailTabSubj$.next(true);
    }
  }

  public onHandlePhoneTabPressed(event: Event): void {
    if (event.isTrusted) {
      event.stopPropagation();
      event.preventDefault();
      switch (this.orderDetailsForm.controls.phone.status) {
        case 'PENDING':
          this.phoneTabSubj$.next(true);
          break;
        case 'INVALID':
          this.orderDetailsForm.controls.phone.markAsTouched();
          break;
        default:
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setEmailFromParam(): void {
    this.activatedRoute.queryParams
      .pipe(
        first(),
        map((params) => params?.email),
        filter((email) => !!email),
      )
      .subscribe((email) => {
        history.replaceState({}, document.title, window.location.pathname);
        this.orderDetailsForm.patchValue({ email });
        this.orderDetailsForm.controls.email.markAsTouched();
      });
  }
}
