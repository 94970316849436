import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputWrapperComponent } from './input-wrapper/input-wrapper.component';
import { InputCtrlDirective } from './input-ctrl.directive';

@NgModule({
  declarations: [InputWrapperComponent, InputCtrlDirective],
  exports: [InputWrapperComponent, InputCtrlDirective],
  imports: [CommonModule],
})
export class AppInputModule {}
