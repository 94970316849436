import { Injectable } from '@angular/core';
import { NgControl } from '@angular/forms';

@Injectable()
export class InputService {
  get hasError(): any {
    return this.control && this.control.touched && this.control.errors;
  }
  private control!: NgControl;

  public isFocused = false;
  public isDisabled = false;

  public setControl(control: NgControl): void {
    this.control = control;
  }
}
