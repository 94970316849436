import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { InputPromoComponent } from './input-promo/input-promo.component';
import { ModalService } from 'src/app/core/service/modal-state.service';
import { TPlanType } from 'src/app/core/models/i-order-state.model';
import { TPromoCodeModel } from 'src/app/core/models/ipromo-code.model';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss'],
})
export class OrderPaymentComponent {
  @ViewChild(InputPromoComponent, { static: true })
  public inputPromo!: InputPromoComponent;

  @Input()
  public isValid = false;
  @Input()
  public reCaptchaFailed = false;
  @Input()
  public counter: number | null;
  @Output()
  public toggleReview = new EventEmitter<void>();

  public get errorTransactionMsg(): string | undefined {
    return this.orderState.stateValue().errorTransactionMsg;
  }

  public get promoCode(): TPromoCodeModel | null {
    return this.orderState.promoCodeData();
  }

  public get isReview(): boolean {
    return this.orderState.stateValue().isReview;
  }

  public get planType(): TPlanType {
    return this.orderState.stateValue().planType;
  }

  public get isVendorSpecific(): boolean {
    return this.orderState.isVendorSpecific;
  }

  public get isHybridVendorPlan(): boolean {
    return this.orderState.isHybridVendorPlan;
  }

  constructor(public orderState: OrderStateService, public modalService: ModalService) {}
}
